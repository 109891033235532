:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #495057;
  --text-color-secondary: #6c757d;
  --primary-color: #1b234e;
  --primary-color-text: #ffffff;
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --gray-50: #fafafa;
  --gray-100: #f5f5f5;
  --gray-200: #eeeeee;
  --gray-300: #e0e0e0;
  --gray-400: #bdbdbd;
  --gray-500: #9e9e9e;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
  --content-padding: 1.25rem;
  --inline-spacing: 0.5rem;
  --border-radius: 6px;
  --surface-ground: #eff3f8;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dfe7ef;
  --surface-hover: #f6f9fc;
  --focus-ring: 0 0 0 0.2rem #bfdbfe;
  --maskbg: rgba(0, 0, 0, 0.4);
  color-scheme: light;
}

* {
  box-sizing: border-box;
}

.p-component {
  font-size: 1rem;
  font-weight: normal;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(15px);
}

.p-disabled,
.p-component:disabled {
  opacity: 0.6;
}

.p-error {
  color: #e24c4c;
}

.p-text-secondary {
  color: #6c757d;
}

.pi {
  font-size: 0.7rem;
}

.p-link {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  border-radius: 6px;

  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfdbfe;
  }
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }

  to {
    background-color: var(--maskbg);
  }
}

@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }

  to {
    background-color: transparent;
  }
}

:root {
  --blue-50: #f5f9ff;
  --blue-100: #d0e1fd;
  --blue-200: #abc9fb;
  --blue-300: #85b2f9;
  --blue-400: #609af8;
  --blue-500: #3b82f6;
  --blue-600: #326fd1;
  --blue-700: #295bac;
  --blue-800: #204887;
  --blue-900: #183462;
  --green-50: #f4fcf7;
  --green-100: #caf1d8;
  --green-200: #a0e6ba;
  --green-300: #76db9b;
  --green-400: #4cd07d;
  --green-500: #22c55e;
  --green-600: #1da750;
  --green-700: #188a42;
  --green-800: #136c34;
  --green-900: #0e4f26;
  --yellow-50: #fefbf3;
  --yellow-100: #faedc4;
  --yellow-200: #f6de95;
  --yellow-300: #f2d066;
  --yellow-400: #eec137;
  --yellow-500: #eab308;
  --yellow-600: #c79807;
  --yellow-700: #a47d06;
  --yellow-800: #816204;
  --yellow-900: #5e4803;
  --cyan-50: #f3fbfd;
  --cyan-100: #c3edf5;
  --cyan-200: #94e0ed;
  --cyan-300: #65d2e4;
  --cyan-400: #35c4dc;
  --cyan-500: #06b6d4;
  --cyan-600: #059bb4;
  --cyan-700: #047f94;
  --cyan-800: #036475;
  --cyan-900: #024955;
  --pink-50: #fef6fa;
  --pink-100: #fad3e7;
  --pink-200: #f7b0d3;
  --pink-300: #f38ec0;
  --pink-400: #f06bac;
  --pink-500: #ec4899;
  --pink-600: #c93d82;
  --pink-700: #a5326b;
  --pink-800: #822854;
  --pink-900: #5e1d3d;
  --indigo-50: #f7f7fe;
  --indigo-100: #dadafc;
  --indigo-200: #bcbdf9;
  --indigo-300: #9ea0f6;
  --indigo-400: #8183f4;
  --indigo-500: #6366f1;
  --indigo-600: #5457cd;
  --indigo-700: #4547a9;
  --indigo-800: #363885;
  --indigo-900: #282960;
  --teal-50: #f3fbfb;
  --teal-100: #c7eeea;
  --teal-200: #9ae0d9;
  --teal-300: #6dd3c8;
  --teal-400: #41c5b7;
  --teal-500: #14b8a6;
  --teal-600: #119c8d;
  --teal-700: #0e8174;
  --teal-800: #0b655b;
  --teal-900: #084a42;
  --orange-50: #fff8f3;
  --orange-100: #feddc7;
  --orange-200: #fcc39b;
  --orange-300: #fba86f;
  --orange-400: #fa8e42;
  --orange-500: #f97316;
  --orange-600: #d46213;
  --orange-700: #ae510f;
  --orange-800: #893f0c;
  --orange-900: #642e09;
  --bluegray-50: #f7f8f9;
  --bluegray-100: #dadee3;
  --bluegray-200: #bcc3cd;
  --bluegray-300: #9fa9b7;
  --bluegray-400: #818ea1;
  --bluegray-500: #64748b;
  --bluegray-600: #556376;
  --bluegray-700: #465161;
  --bluegray-800: #37404c;
  --bluegray-900: #282e38;
  --purple-50: #fbf7ff;
  --purple-100: #ead6fd;
  --purple-200: #dab6fc;
  --purple-300: #c996fa;
  --purple-400: #b975f9;
  --purple-500: #a855f7;
  --purple-600: #8f48d2;
  --purple-700: #763cad;
  --purple-800: #5c2f88;
  --purple-900: #432263;
  --red-50: #fff5f5;
  --red-100: #ffd0ce;
  --red-200: #ffaca7;
  --red-300: #ff8780;
  --red-400: #ff6259;
  --red-500: #ff3d32;
  --red-600: #d9342b;
  --red-700: #b32b23;
  --red-800: #8c221c;
  --red-900: #661814;
  --primary-50: #e6e9ef;
  --primary-100: #c1c7da;
  --primary-200: #99a3c0;
  --primary-300: #7380a7;
  --primary-400: #576595;
  --primary-500: #1b234e;
  --primary-600: #35457d;
  --primary-700: #2d3c72;
  --primary-800: #263265;
  --primary-900: #183462;
}

.p-autocomplete {
  .p-autocomplete-loader {
    right: 0.75rem;
  }

  &.p-autocomplete-dd .p-autocomplete-loader {
    right: 3.75rem;
  }

  .p-autocomplete-multiple-container {
    padding: 0.375rem 0.75rem;

    &:not(.p-disabled) {
      &:hover {
        border-color: #1b234e;
      }

      &.p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
        border-color: #1b234e;
      }
    }

    .p-autocomplete-input-token {
      padding: 0.375rem 0;

      input {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
          'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        font-size: 1rem;
        color: #495057;
        padding: 0;
        margin: 0;
      }
    }

    .p-autocomplete-token {
      padding: 0.375rem 0.75rem;
      margin-right: 0.5rem;
      background: #eff6ff;
      color: #1d4ed8;
      border-radius: 6px;

      .p-autocomplete-token-icon {
        margin-left: 0.5rem;
      }
    }
  }
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: #e24c4c;
}

.p-autocomplete-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .p-autocomplete-items {
    padding: 0.75rem 0;

    .p-autocomplete-item {
      margin: 0;
      padding: 0.75rem 1.25rem;
      border: 0 none;
      color: #495057;
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;

      &:hover {
        color: #495057;
        background: #e9ecef;
      }

      &.p-highlight {
        color: #1d4ed8;
        background: #eff6ff;
      }
    }

    .p-autocomplete-empty-message {
      padding: 0.75rem 1.25rem;
      color: #495057;
      background: transparent;
    }

    .p-autocomplete-item-group {
      margin: 0;
      padding: 0.75rem 1.25rem;
      color: #343a40;
      background: #ffffff;
      font-weight: 700;
    }
  }
}

p-autocomplete.p-autocomplete-clearable {
  .p-inputtext {
    padding-right: 2.5rem;
  }

  .p-autocomplete-clear-icon {
    color: #6c757d;
    right: 0.75rem;
  }

  .p-autocomplete-dd .p-autocomplete-clear-icon {
    color: #6c757d;
    right: 3.75rem;
  }
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #e24c4c;
}

.p-datepicker {
  padding: 0.5rem;
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 6px;

  &:not(.p-datepicker-inline) {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .p-datepicker-header {
      background: #ffffff;
    }
  }

  .p-datepicker-header {
    padding: 0.5rem;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #dee2e6;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .p-datepicker-prev,
    .p-datepicker-next {
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-datepicker-prev:enabled:hover,
    .p-datepicker-next:enabled:hover {
      color: #343a40;
      border-color: transparent;
      background: #e9ecef;
    }

    .p-datepicker-prev:focus,
    .p-datepicker-next:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
    }

    .p-datepicker-title {
      line-height: 2rem;

      .p-datepicker-year,
      .p-datepicker-month {
        color: #495057;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        font-weight: 600;
        padding: 0.5rem;
      }

      .p-datepicker-year:enabled:hover {
        color: #1b234e;
      }

      .p-datepicker-month {
        &:enabled:hover {
          color: #1b234e;
        }

        margin-right: 0.5rem;
      }
    }
  }

  table {
    font-size: 1rem;
    margin: 0.5rem 0;

    th {
      padding: 0.5rem;

      > span {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    td {
      padding: 0.5rem;

      > span {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 80%;
        transition: box-shadow 0.2s;
        border: 1px solid transparent;

        &.p-highlight {
          color: #1d4ed8;
          background: #eff6ff;
        }

        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #bfdbfe;
        }
      }

      &.p-datepicker-today > span {
        background: #ced4da;
        color: #495057;
        border-color: transparent;

        &.p-highlight {
          color: #1d4ed8;
          background: #eff6ff;
        }
      }
    }
  }

  .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid #dee2e6;

    .p-button {
      width: auto;
    }
  }

  .p-timepicker {
    border-top: 1px solid #dee2e6;
    padding: 0.5rem;

    button {
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

      &:enabled:hover {
        color: #343a40;
        border-color: transparent;
        background: #e9ecef;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
      }

      &:last-child {
        margin-top: 0.2em;
      }
    }

    span {
      font-size: 1.25rem;
    }

    > div {
      padding: 0 0.5rem;
    }
  }

  &.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none;
  }

  .p-monthpicker {
    margin: 0.5rem 0;

    .p-monthpicker-month {
      padding: 0.5rem;
      transition: box-shadow 0.2s;
      border-radius: 6px;

      &.p-highlight {
        color: #1d4ed8;
        background: #eff6ff;
      }
    }
  }

  .p-yearpicker {
    margin: 0.5rem 0;

    .p-yearpicker-year {
      padding: 0.5rem;
      transition: box-shadow 0.2s;
      border-radius: 6px;

      &.p-highlight {
        color: #1d4ed8;
        background: #eff6ff;
      }
    }
  }

  &.p-datepicker-multiple-month .p-datepicker-group {
    border-left: 1px solid #dee2e6;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;

    &:first-child {
      padding-left: 0;
      border-left: 0 none;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &:not(.p-disabled) {
    table td span:not(.p-highlight):not(.p-disabled) {
      &:hover {
        background: #e9ecef;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
      }
    }

    .p-monthpicker .p-monthpicker-month:not(.p-disabled),
    .p-yearpicker .p-yearpicker-year:not(.p-disabled) {
      &:not(.p-highlight):hover {
        background: #e9ecef;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
      }
    }
  }
}

p-calendar.p-calendar-clearable {
  .p-inputtext {
    padding-right: 2.5rem;
  }

  .p-calendar-clear-icon {
    color: #6c757d;
    right: 0.75rem;
  }

  &.p-calendar-w-btn .p-calendar-clear-icon {
    color: #6c757d;
    right: 3.75rem;
  }
}

@media screen and (max-width: 769px) {
  .p-datepicker table {
    th,
    td {
      padding: 0;
    }
  }
}

.p-cascadeselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;

  &:not(.p-disabled) {
    &:hover {
      border-color: #1b234e;
    }

    &.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
      border-color: #1b234e;
    }
  }

  .p-cascadeselect-label {
    background: transparent;
    border: 0 none;
    padding: 0.75rem 0.75rem;

    &.p-placeholder {
      color: #6c757d;
    }

    &:enabled:focus {
      outline: 0 none;
      box-shadow: none;
    }
  }

  .p-cascadeselect-trigger {
    background: transparent;
    color: #6c757d;
    width: 3rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.p-cascadeselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .p-cascadeselect-items {
    padding: 0.75rem 0;

    .p-cascadeselect-item {
      margin: 0;
      border: 0 none;
      color: #495057;
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;

      .p-cascadeselect-item-content {
        padding: 0.75rem 1.25rem;

        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.15rem #bfdbfe;
        }
      }

      &.p-highlight {
        color: #1d4ed8;
        background: #eff6ff;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        color: #495057;
        background: #e9ecef;
      }

      .p-cascadeselect-group-icon {
        font-size: 0.875rem;
      }
    }
  }
}

p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
  border-color: #e24c4c;
}

.p-input-filled .p-cascadeselect {
  background: #e9ecef;

  &:not(.p-disabled) {
    &:hover {
      background-color: #e9ecef;
    }

    &.p-focus {
      background-color: #ffffff;
    }
  }
}

p-cascadeselect.p-cascadeselect-clearable {
  .p-cascadeselect-label {
    padding-right: 0.75rem;
  }

  .p-cascadeselect-clear-icon {
    color: #6c757d;
    right: 3rem;
  }
}

.p-checkbox {
  width: 22px;
  height: 22px;

  .p-checkbox-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 22px;
    height: 22px;
    color: #495057;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

    .p-checkbox-icon {
      transition-duration: 0.2s;
      color: #ffffff;
      font-size: 14px;
    }

    &.p-highlight {
      border-color: #1b234e;
      background: #1b234e;
    }
  }

  &:not(.p-checkbox-disabled) .p-checkbox-box {
    &:hover {
      border-color: #1b234e;
    }

    &.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
      border-color: #1b234e;
    }

    &.p-highlight:hover {
      border-color: #1d4ed8;
      background: #1d4ed8;
      color: #ffffff;
    }
  }
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #e24c4c;
}

.p-input-filled .p-checkbox {
  .p-checkbox-box {
    background-color: #e9ecef;

    &.p-highlight {
      background: #1b234e;
    }
  }

  &:not(.p-checkbox-disabled) .p-checkbox-box {
    &:hover {
      background-color: #e9ecef;
    }

    &.p-highlight:hover {
      background: #1d4ed8;
    }
  }
}

.p-checkbox-label {
  margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container {
  padding: 0.375rem 0.75rem;

  &:not(.p-disabled) {
    &:hover {
      border-color: #1b234e;
    }

    &.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
      border-color: #1b234e;
    }
  }

  .p-chips-token {
    padding: 0.375rem 0.75rem;
    margin-right: 0.5rem;
    background: #eff6ff;
    color: #1d4ed8;
    border-radius: 6px;

    .p-chips-token-icon {
      margin-left: 0.5rem;
    }
  }

  .p-chips-input-token {
    padding: 0.375rem 0;

    input {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      font-size: 1rem;
      color: #495057;
      padding: 0;
      margin: 0;
    }
  }
}

p-chips {
  &.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
    border-color: #e24c4c;
  }

  &.p-chips-clearable {
    .p-inputtext {
      padding-right: 1.75rem;
    }

    .p-chips-clear-icon {
      color: #6c757d;
      right: 0.75rem;
    }
  }
}

.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #323232;
  border: 1px solid #191919;

  .p-colorpicker-color-handle,
  .p-colorpicker-hue-handle {
    border-color: #ffffff;
  }
}

.p-colorpicker-overlay-panel {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.p-dropdown {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  width: 100%;

  &:not(.p-disabled) {
    &:hover {
      border-color: #1b234e;
    }

    &.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
      border-color: #1b234e;
    }
  }

  &.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.75rem;
  }

  .p-dropdown-label {
    background: transparent;
    border: 0 none;

    &.p-placeholder {
      color: #6c757d;
    }

    &:enabled:focus {
      outline: 0 none;
      box-shadow: none;
    }
  }

  .p-dropdown-trigger {
    background: transparent;
    color: #6c757d;
    width: 3rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .p-dropdown-clear-icon {
    color: #6c757d;
    right: 3rem;
  }
}

.p-dropdown-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .p-dropdown-header {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #dee2e6;
    color: #343a40;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .p-dropdown-filter {
      padding-right: 1.75rem;
      margin-right: -1.75rem;
    }

    .p-dropdown-filter-icon {
      right: 0.75rem;
      color: #6c757d;
    }
  }

  .p-dropdown-items {
    padding: 0.75rem 0;

    .p-dropdown-item {
      margin: 0;
      padding: 0.75rem 1.25rem;
      border: 0 none;
      color: #495057;
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;

      &.p-highlight {
        color: #1d4ed8;
        background: #eff6ff;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        color: #495057;
        background: #e9ecef;
      }
    }

    .p-dropdown-empty-message {
      padding: 0.75rem 1.25rem;
      color: #495057;
      background: transparent;
    }

    .p-dropdown-item-group {
      margin: 0;
      padding: 0.75rem 1.25rem;
      color: #343a40;
      background: #ffffff;
      font-weight: 700;
    }
  }
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #e24c4c;
}

.p-input-filled .p-dropdown {
  background: #e9ecef;

  &:not(.p-disabled) {
    &:hover {
      background-color: #e9ecef;
    }

    &.p-focus {
      background-color: #ffffff;

      .p-inputtext {
        background-color: transparent;
      }
    }
  }
}

.p-editor-container {
  .p-editor-toolbar {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    &.ql-snow {
      border: 1px solid #94a3b8;

      .ql-stroke {
        stroke: #94a3b8;
      }

      .ql-fill {
        fill: #6c757d;
      }

      .ql-picker {
        .ql-picker-label {
          border: 0 none;
          color: #94a3b8;

          &:hover {
            color: #495057;

            .ql-stroke {
              stroke: #495057;
            }

            .ql-fill {
              fill: #495057;
            }
          }
        }

        &.ql-expanded {
          .ql-picker-label {
            color: #94a3b8;

            .ql-stroke {
              stroke: #495057;
            }

            .ql-fill {
              fill: #495057;
            }
          }

          .ql-picker-options {
            background: #ffffff;
            border: 0 none;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            border-radius: 6px;
            padding: 0.75rem 0;

            .ql-picker-item {
              color: #495057;

              &:hover {
                color: #495057;
                background: #e9ecef;
              }
            }
          }

          &:not(.ql-icon-picker) .ql-picker-item {
            padding: 0.75rem 1.25rem;
          }
        }
      }
    }
  }

  .p-editor-content {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    .ql-tooltip {
      z-index: 1 !important;
    }

    &.ql-snow {
      border: 1px solid #94a3b8;
    }

    .ql-editor {
      background: #ffffff;
      color: #94a3b8;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  .ql-snow.ql-toolbar {
    button {
      &:hover,
      &:focus {
        color: #495057;
      }

      &:hover .ql-stroke,
      &:focus .ql-stroke {
        stroke: #495057;
      }

      &:hover .ql-fill,
      &:focus .ql-fill {
        fill: #495057;
      }

      &.ql-active {
        color: #1b234e;
      }
    }

    .ql-picker-label.ql-active,
    .ql-picker-item.ql-selected {
      color: #1b234e;
    }

    button.ql-active .ql-stroke,
    .ql-picker-label.ql-active .ql-stroke,
    .ql-picker-item.ql-selected .ql-stroke {
      stroke: #1b234e;
    }

    button.ql-active .ql-fill,
    .ql-picker-label.ql-active .ql-fill,
    .ql-picker-item.ql-selected .ql-fill {
      fill: #1b234e;
    }

    button.ql-active .ql-picker-label,
    .ql-picker-label.ql-active .ql-picker-label,
    .ql-picker-item.ql-selected .ql-picker-label {
      color: #1b234e;
    }
  }
}

.p-inputgroup-addon {
  background: #e9ecef;
  color: #6c757d;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  padding: 0.75rem 0.75rem;
  min-width: 3rem;

  &:last-child {
    border-right: 1px solid #ced4da;
  }
}

.p-inputgroup > {
  .p-component,
  .p-element,
  .p-inputwrapper > .p-component > .p-inputtext,
  .p-float-label > .p-component {
    border-radius: 0;
    margin: 0;
  }

  .p-component + .p-inputgroup-addon,
  .p-element + .p-inputgroup-addon,
  .p-inputwrapper > .p-component > .p-inputtext + .p-inputgroup-addon,
  .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0 none;
  }

  .p-component:focus,
  .p-element:focus,
  .p-inputwrapper > .p-component > .p-inputtext:focus,
  .p-float-label > .p-component:focus,
  .p-component:focus ~ label,
  .p-element:focus ~ label,
  .p-inputwrapper > .p-component > .p-inputtext:focus ~ label,
  .p-float-label > .p-component:focus ~ label {
    z-index: 1;
  }
}

.p-inputgroup-addon:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-inputgroup {
  button:first-child,
  input:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  > .p-inputwrapper:first-child > .p-component {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

    > .p-inputtext {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  .p-float-label:first-child input {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

.p-inputgroup-addon:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-inputgroup {
  button:last-child,
  input:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  > .p-inputwrapper:last-child > .p-component {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    > .p-inputtext {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .p-float-label:last-child input {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.p-fluid .p-inputgroup .p-button {
  width: auto;

  &.p-button-icon-only {
    width: 3rem;
  }
}

p-inputmask {
  &.ng-dirty.ng-invalid > .p-inputtext {
    border-color: #e24c4c;
  }

  &.p-inputmask-clearable {
    .p-inputtext {
      padding-right: 2.5rem;
    }

    .p-inputmask-clear-icon {
      color: #6c757d;
      right: 0.75rem;
    }
  }
}

p-inputnumber {
  &.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
    border-color: #e24c4c;
  }

  &.p-inputnumber-clearable {
    .p-inputnumber-input {
      padding-right: 2.5rem;
    }

    .p-inputnumber-clear-icon {
      color: #6c757d;
      right: 0.75rem;
    }

    .p-inputnumber-buttons-stacked .p-inputnumber-clear-icon,
    .p-inputnumber-buttons-horizontal .p-inputnumber-clear-icon {
      right: 3.75rem;
    }
  }
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;

  .p-inputswitch-slider {
    background: #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 30px;

    &:before {
      background: #ffffff;
      width: 1.25rem;
      height: 1.25rem;
      left: 0.25rem;
      margin-top: -0.625rem;
      border-radius: 50%;
      transition-duration: 0.2s;
    }
  }

  &.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.25rem);
  }

  &.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfdbfe;
  }

  &:not(.p-disabled):hover .p-inputswitch-slider {
    background: #b6bfc8;
  }

  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: #1b234e;

      &:before {
        background: #ffffff;
      }
    }

    &:not(.p-disabled):hover .p-inputswitch-slider {
      background: #2563eb;
    }
  }
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: #e24c4c;
}

.p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #94a3b8;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;

  &:enabled {
    &:hover {
      border-color: #1b234e;
    }

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
      border-color: #1b234e;
    }
  }

  &.ng-dirty.ng-invalid {
    border-color: #e24c4c;
  }

  &.p-inputtext-sm {
    font-size: 0.875rem;
    padding: 0.65625rem 0.65625rem;
  }

  &.p-inputtext-lg {
    font-size: 1.25rem;
    padding: 0.9375rem 0.9375rem;
  }

  &::placeholder {
    color: #94a3b8;
    font-size: 0.75rem;
  }
}

.p-float-label > {
  label {
    left: 0.75rem;
    color: #6c757d;
    transition-duration: 0.2s;
  }

  .ng-invalid.ng-dirty + label {
    color: #e24c4c;
  }
}

.p-input-icon-left {
  > {
    i:first-of-type {
      left: 0.75rem;
      color: #6c757d;
    }

    .p-inputtext {
      padding-left: 2.5rem;
    }
  }

  &.p-float-label > label {
    left: 2.5rem;
  }
}

.p-input-icon-right > {
  i:last-of-type {
    right: 0.75rem;
    color: #6c757d;
  }

  .p-inputtext {
    padding-right: 2.5rem;
  }
}

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  color: #6c757d;
}

.p-input-filled .p-inputtext {
  background-color: #e9ecef;

  &:enabled {
    &:hover {
      background-color: #e9ecef;
    }

    &:focus {
      background-color: #ffffff;
    }
  }
}

.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem;
}

.p-listbox {
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 6px;

  .p-listbox-header {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #dee2e6;
    color: #343a40;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .p-listbox-filter {
      padding-right: 1.75rem;
    }

    .p-listbox-filter-icon {
      right: 0.75rem;
      color: #6c757d;
    }

    .p-checkbox {
      margin-right: 0.5rem;
    }
  }

  .p-listbox-list {
    padding: 0.75rem 0;

    .p-listbox-item {
      margin: 0;
      padding: 0.75rem 1.25rem;
      border: 0 none;
      color: #495057;
      transition: box-shadow 0.2s;
      border-radius: 0;

      &.p-highlight {
        color: #1d4ed8;
        background: #eff6ff;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem #bfdbfe;
      }

      .p-checkbox {
        margin-right: 0.5rem;
      }
    }

    .p-listbox-item-group {
      margin: 0;
      padding: 0.75rem 1.25rem;
      color: #343a40;
      background: #ffffff;
      font-weight: 700;
    }

    .p-listbox-empty-message {
      padding: 0.75rem 1.25rem;
      color: #495057;
      background: transparent;
    }
  }

  &:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: #495057;
    background: #e9ecef;
  }
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: #e24c4c;
}

.p-multiselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;

  &:not(.p-disabled) {
    &:hover {
      border-color: #1b234e;
    }

    &.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
      border-color: #1b234e;
    }
  }

  .p-multiselect-label {
    padding: 0.75rem 0.75rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

    &.p-placeholder {
      color: #6c757d;
    }
  }

  &.p-multiselect-chip .p-multiselect-token {
    padding: 0.375rem 0.75rem;
    margin-right: 0.5rem;
    background: #eff6ff;
    color: #1d4ed8;
    border-radius: 6px;

    .p-multiselect-token-icon {
      margin-left: 0.5rem;
    }
  }

  .p-multiselect-trigger {
    background: transparent;
    color: #6c757d;
    width: 3rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.375rem 0.75rem;
}

.p-multiselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .p-multiselect-header {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #dee2e6;
    color: #343a40;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .p-multiselect-filter-container {
      .p-inputtext {
        padding-right: 1.75rem;
      }

      .p-multiselect-filter-icon {
        right: 0.75rem;
        color: #6c757d;
      }
    }

    .p-checkbox {
      margin-right: 0.5rem;
    }

    .p-multiselect-close {
      margin-left: 0.5rem;
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

      &:enabled:hover {
        color: #343a40;
        border-color: transparent;
        background: #e9ecef;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
      }
    }
  }

  .p-multiselect-items {
    padding: 0.75rem 0;

    .p-multiselect-item {
      margin: 0;
      padding: 0.75rem 1.25rem;
      border: 0 none;
      color: #495057;
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;

      &.p-highlight {
        color: #1d4ed8;
        background: #eff6ff;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        color: #495057;
        background: #e9ecef;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem #bfdbfe;
      }

      .p-checkbox {
        margin-right: 0.5rem;
      }
    }

    .p-multiselect-item-group {
      margin: 0;
      padding: 0.75rem 1.25rem;
      color: #343a40;
      background: #ffffff;
      font-weight: 700;
    }

    .p-multiselect-empty-message {
      padding: 0.75rem 1.25rem;
      color: #495057;
      background: transparent;
    }
  }
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #e24c4c;
}

.p-input-filled .p-multiselect {
  background: #e9ecef;

  &:not(.p-disabled) {
    &:hover {
      background-color: #e9ecef;
    }

    &.p-focus {
      background-color: #ffffff;
    }
  }
}

p-multiselect.p-multiselect-clearable {
  .p-multiselect-label-container {
    padding-right: 1.75rem;
  }

  .p-multiselect-clear-icon {
    color: #6c757d;
    right: 3rem;
  }
}

p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
  border-color: #e24c4c;
}

.p-password-panel {
  padding: 1.25rem;
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  .p-password-meter {
    margin-bottom: 0.5rem;
    background: #dee2e6;

    .p-password-strength {
      &.weak {
        background: #ea5455;
      }

      &.medium {
        background: #ff9f42;
      }

      &.strong {
        background: #29c76f;
      }
    }
  }
}

p-password.p-password-clearable {
  .p-password-input {
    padding-right: 2.5rem;
  }

  .p-password-clear-icon {
    color: #6c757d;
    right: 0.75rem;
  }

  &.p-password-mask {
    .p-password-input {
      padding-right: 4.25rem;
    }

    .p-password-clear-icon {
      color: #6c757d;
      right: 2.5rem;
    }
  }
}

.p-radiobutton {
  width: 22px;
  height: 22px;

  .p-radiobutton-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 22px;
    height: 22px;
    color: #495057;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

    &:not(.p-disabled) {
      &:not(.p-highlight):hover {
        border-color: #1b234e;
      }

      &.p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
        border-color: #1b234e;
      }
    }

    .p-radiobutton-icon {
      width: 12px;
      height: 12px;
      transition-duration: 0.2s;
      background-color: #ffffff;
    }

    &.p-highlight {
      border-color: #1b234e;
      background: #1b234e;

      &:not(.p-disabled):hover {
        border-color: #1d4ed8;
        background: #1d4ed8;
        color: #ffffff;
      }
    }
  }
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: #e24c4c;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #e9ecef;

  &:not(.p-disabled):hover {
    background-color: #e9ecef;
  }

  &.p-highlight {
    background: #1b234e;

    &:not(.p-disabled):hover {
      background: #1d4ed8;
    }
  }
}

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.p-rating {
  .p-rating-icon {
    color: #495057;
    margin-left: 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    font-size: 1.143rem;

    &.p-rating-cancel {
      color: #ea5455;
    }

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
    }

    &:first-child {
      margin-left: 0;
    }

    &.pi-star-fill {
      color: #1b234e;
    }
  }

  &:not(.p-disabled):not(.p-readonly) .p-rating-icon {
    &:hover {
      color: #1b234e;
    }

    &.p-rating-cancel:hover {
      color: #e73d3e;
    }
  }
}

.p-selectbutton .p-button {
  padding: 0.5rem;
  width: auto;
  height: auto;
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

  .p-button-icon-left,
  .p-button-icon-right {
    color: #6c757d;
  }

  &:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
    border-color: #ced4da;
    color: #495057;

    .p-button-icon-left,
    .p-button-icon-right {
      color: #343a40;
    }
  }

  &.p-highlight {
    background: #1b234e;
    border-color: #1b234e;
    color: #ffffff;

    .p-button-icon-left,
    .p-button-icon-right {
      color: #ffffff;
    }

    &:hover {
      background: #2563eb;
      border-color: #2563eb;
      color: #ffffff;

      .p-button-icon-left,
      .p-button-icon-right {
        color: #ffffff;
      }
    }
  }
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: #e24c4c;
}

.p-slider {
  background: #dee2e6;
  border: 0 none;
  border-radius: 6px;

  &.p-slider-horizontal {
    height: 0.286rem;

    .p-slider-handle {
      margin-top: -0.5715rem;
      margin-left: -0.5715rem;
    }
  }

  &.p-slider-vertical {
    width: 0.286rem;

    .p-slider-handle {
      margin-left: -0.5715rem;
      margin-bottom: -0.5715rem;
    }
  }

  .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: #ffffff;
    border: 2px solid #1b234e;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
  }

  .p-slider-range {
    background: #1b234e;
  }

  &:not(.p-disabled) .p-slider-handle:hover {
    background: #1b234e;
    border-color: #1b234e;
  }

  &.p-slider-animate {
    &.p-slider-horizontal {
      .p-slider-handle {
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
      }

      .p-slider-range {
        transition: width 0.2s;
      }
    }

    &.p-slider-vertical {
      .p-slider-handle {
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
      }

      .p-slider-range {
        transition: height 0.2s;
      }
    }
  }
}

.p-togglebutton.p-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

  .p-button-icon-left,
  .p-button-icon-right {
    color: #6c757d;
  }

  &:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
    border-color: #ced4da;
    color: #495057;

    .p-button-icon-left,
    .p-button-icon-right {
      color: #343a40;
    }
  }

  &.p-highlight {
    background: #1b234e;
    border-color: #1b234e;
    color: #ffffff;

    .p-button-icon-left,
    .p-button-icon-right {
      color: #ffffff;
    }

    &:hover {
      background: #2563eb;
      border-color: #2563eb;
      color: #ffffff;

      .p-button-icon-left,
      .p-button-icon-right {
        color: #ffffff;
      }
    }
  }
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: #e24c4c;
}

.p-treeselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;

  &:not(.p-disabled) {
    &:hover {
      border-color: #1b234e;
    }

    &.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
      border-color: #1b234e;
    }
  }

  .p-treeselect-label {
    padding: 0.75rem 0.75rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

    &.p-placeholder {
      color: #6c757d;
    }
  }

  &.p-treeselect-chip .p-treeselect-token {
    padding: 0.375rem 0.75rem;
    margin-right: 0.5rem;
    background: #dee2e6;
    color: #495057;
    border-radius: 16px;
  }

  .p-treeselect-trigger {
    background: transparent;
    color: #6c757d;
    width: 3rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
  border-color: #e24c4c;
}

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: 0.375rem 0.75rem;
}

.p-treeselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .p-treeselect-header {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #dee2e6;
    color: #343a40;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .p-treeselect-filter-container {
      margin-right: 0.5rem;

      .p-treeselect-filter {
        padding-right: 1.75rem;
      }

      .p-treeselect-filter-icon {
        right: 0.75rem;
        color: #6c757d;
      }

      &.p-treeselect-clearable-filter {
        .p-treeselect-filter {
          padding-right: 3.5rem;
        }

        .p-treeselect-filter-clear-icon {
          right: 2.5rem;
        }
      }
    }

    .p-treeselect-close {
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

      &:enabled:hover {
        color: #343a40;
        border-color: transparent;
        background: #e9ecef;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
      }
    }
  }

  .p-treeselect-items-wrapper {
    .p-tree {
      border: 0 none;
    }

    .p-treeselect-empty-message {
      padding: 0.75rem 1.25rem;
      color: #495057;
      background: transparent;
    }
  }
}

.p-input-filled .p-treeselect {
  background: #e9ecef;

  &:not(.p-disabled) {
    &:hover {
      background-color: #e9ecef;
    }

    &.p-focus {
      background-color: #ffffff;
    }
  }
}

p-treeselect.p-treeselect-clearable {
  .p-treeselect-label-container {
    padding-right: 1.75rem;
  }

  .p-treeselect-clear-icon {
    color: #6c757d;
    right: 3rem;
  }
}

.p-button {
  color: #ffffff;
  background: #1b234e;
  border: 1px solid #1b234e;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  height: 3rem;
  width: 18rem;

  &:enabled {
    &:hover {
      background: #2563eb;
      color: #ffffff;
      border-color: #2563eb;
    }

    &:active {
      background: #1d4ed8;
      color: #ffffff;
      border-color: #1d4ed8;
    }
  }

  &.p-button-outlined {
    background-color: transparent;
    color: #1b234e;
    border: 3px solid #1b234e;

    &:enabled {
      &:hover {
        background: rgba(59, 130, 246, 0.04);
        color: #1b234e;
      }

      &:active {
        background: rgba(59, 130, 246, 0.16);
        color: #1b234e;
      }
    }

    &.p-button-plain {
      color: #6c757d;
      border-color: #6c757d;

      &:enabled {
        &:hover {
          background: #e9ecef;
          color: #6c757d;
        }

        &:active {
          background: #dee2e6;
          color: #6c757d;
        }
      }
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: #1b234e;
    border-color: transparent;

    &:enabled {
      &:hover {
        background: rgba(59, 130, 246, 0.04);
        color: #1b234e;
        border-color: transparent;
      }

      &:active {
        background: rgba(59, 130, 246, 0.16);
        color: #1b234e;
        border-color: transparent;
      }
    }

    &.p-button-plain {
      color: #6c757d;

      &:enabled {
        &:hover {
          background: #e9ecef;
          color: #6c757d;
        }

        &:active {
          background: #dee2e6;
          color: #6c757d;
        }
      }
    }
  }

  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfdbfe;
  }

  .p-button-icon-left {
    margin-right: 0.5rem;
  }

  .p-button-icon-right {
    margin-left: 0.5rem;
  }

  .p-button-icon-bottom {
    margin-top: 0.5rem;
  }

  .p-button-icon-top {
    margin-bottom: 0.5rem;
  }

  .p-badge {
    margin-left: 0.5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: #1b234e;
    background-color: #ffffff;
  }

  &.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  &.p-button-rounded {
    border-radius: 2rem;
  }

  &.p-button-icon-only {
    width: 3rem;
    padding: 0.75rem 0;

    .p-button-icon-left,
    .p-button-icon-right {
      margin: 0;
    }

    &.p-button-rounded {
      border-radius: 50%;
      height: 3rem;
    }
  }

  &.p-button-sm {
    font-size: 0.875rem;
    padding: 0.65625rem 1.09375rem;

    .p-button-icon {
      font-size: 0.875rem;
    }
  }

  &.p-button-lg {
    font-size: 1.25rem;
    padding: 0.9375rem 1.5625rem;

    .p-button-icon {
      font-size: 1.25rem;
    }
  }

  &.p-button-loading-label-only {
    .p-button-label {
      margin-left: 0.5rem;
    }

    .p-button-loading-icon {
      margin-right: 0;
    }
  }
}

.p-fluid {
  .p-button {
    width: 100%;
  }

  .p-button-icon-only {
    width: 3rem;
  }

  .p-buttonset {
    display: flex;

    .p-button {
      flex: 1;
    }
  }
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  color: #ffffff;
  background: #64748b;
  border: 1px solid #64748b;
}

.p-button.p-button-secondary:enabled:hover,
.p-buttonset.p-button-secondary > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: #475569;
  color: #ffffff;
  border-color: #475569;
}

.p-button.p-button-secondary:enabled:focus,
.p-buttonset.p-button-secondary > .p-button:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #e2e8f0;
}

.p-button.p-button-secondary:enabled:active,
.p-buttonset.p-button-secondary > .p-button:enabled:active,
.p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: #334155;
  color: #ffffff;
  border-color: #334155;
}

.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #64748b;
  border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(100, 116, 139, 0.04);
  color: #64748b;
  border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(100, 116, 139, 0.16);
  color: #64748b;
  border: 1px solid;
}

.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary > .p-button.p-button-text,
.p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: #64748b;
  border-color: transparent;
}

.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(100, 116, 139, 0.04);
  border-color: transparent;
  color: #64748b;
}

.p-button.p-button-secondary.p-button-text:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: rgba(100, 116, 139, 0.16);
  border-color: transparent;
  color: #64748b;
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
  color: #ffffff;
  background: #1b234e;
  border: 1px solid #1b234e;
}

.p-button.p-button-info:enabled:hover,
.p-buttonset.p-button-info > .p-button:enabled:hover,
.p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: #2563eb;
  color: #ffffff;
  border-color: #2563eb;
}

.p-button.p-button-info:enabled:focus,
.p-buttonset.p-button-info > .p-button:enabled:focus,
.p-splitbutton.p-button-info > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #bfdbfe;
}

.p-button.p-button-info:enabled:active,
.p-buttonset.p-button-info > .p-button:enabled:active,
.p-splitbutton.p-button-info > .p-button:enabled:active {
  background: #1d4ed8;
  color: #ffffff;
  border-color: #1d4ed8;
}

.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: transparent;
  color: #1b234e;
  border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
  background: rgba(59, 130, 246, 0.04);
  color: #1b234e;
  border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(59, 130, 246, 0.16);
  color: #1b234e;
  border: 1px solid;
}

.p-button.p-button-info.p-button-text,
.p-buttonset.p-button-info > .p-button.p-button-text,
.p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: transparent;
  color: #1b234e;
  border-color: transparent;
}

.p-button.p-button-info.p-button-text:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
  background: rgba(59, 130, 246, 0.04);
  border-color: transparent;
  color: #1b234e;
}

.p-button.p-button-info.p-button-text:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
  background: rgba(59, 130, 246, 0.16);
  border-color: transparent;
  color: #1b234e;
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
  color: #ffffff;
  background: #22c55e;
  border: 1px solid #22c55e;
}

.p-button.p-button-success:enabled:hover,
.p-buttonset.p-button-success > .p-button:enabled:hover,
.p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: #16a34a;
  color: #ffffff;
  border-color: #16a34a;
}

.p-button.p-button-success:enabled:focus,
.p-buttonset.p-button-success > .p-button:enabled:focus,
.p-splitbutton.p-button-success > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #bbf7d0;
}

.p-button.p-button-success:enabled:active,
.p-buttonset.p-button-success > .p-button:enabled:active,
.p-splitbutton.p-button-success > .p-button:enabled:active {
  background: #15803d;
  color: #ffffff;
  border-color: #15803d;
}

.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success > .p-button.p-button-outlined,
.p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: #22c55e;
  border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: rgba(34, 197, 94, 0.04);
  color: #22c55e;
  border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(34, 197, 94, 0.16);
  color: #22c55e;
  border: 1px solid;
}

.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: #22c55e;
  border-color: transparent;
}

.p-button.p-button-success.p-button-text:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: rgba(34, 197, 94, 0.04);
  border-color: transparent;
  color: #22c55e;
}

.p-button.p-button-success.p-button-text:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: rgba(34, 197, 94, 0.16);
  border-color: transparent;
  color: #22c55e;
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
  color: #ffffff;
  background: #f59e0b;
  border: 1px solid #f59e0b;
}

.p-button.p-button-warning:enabled:hover,
.p-buttonset.p-button-warning > .p-button:enabled:hover,
.p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: #d97706;
  color: #ffffff;
  border-color: #d97706;
}

.p-button.p-button-warning:enabled:focus,
.p-buttonset.p-button-warning > .p-button:enabled:focus,
.p-splitbutton.p-button-warning > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #fde68a;
}

.p-button.p-button-warning:enabled:active,
.p-buttonset.p-button-warning > .p-button:enabled:active,
.p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: #b45309;
  color: #ffffff;
  border-color: #b45309;
}

.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning > .p-button.p-button-outlined,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: #f59e0b;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: rgba(245, 158, 11, 0.04);
  color: #f59e0b;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(245, 158, 11, 0.16);
  color: #f59e0b;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-text,
.p-buttonset.p-button-warning > .p-button.p-button-text,
.p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: #f59e0b;
  border-color: transparent;
}

.p-button.p-button-warning.p-button-text:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: rgba(245, 158, 11, 0.04);
  border-color: transparent;
  color: #f59e0b;
}

.p-button.p-button-warning.p-button-text:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: rgba(245, 158, 11, 0.16);
  border-color: transparent;
  color: #f59e0b;
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
  color: #ffffff;
  background: #a855f7;
  border: 1px solid #a855f7;
}

.p-button.p-button-help:enabled:hover,
.p-buttonset.p-button-help > .p-button:enabled:hover,
.p-splitbutton.p-button-help > .p-button:enabled:hover {
  background: #9333ea;
  color: #ffffff;
  border-color: #9333ea;
}

.p-button.p-button-help:enabled:focus,
.p-buttonset.p-button-help > .p-button:enabled:focus,
.p-splitbutton.p-button-help > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #e9d5ff;
}

.p-button.p-button-help:enabled:active,
.p-buttonset.p-button-help > .p-button:enabled:active,
.p-splitbutton.p-button-help > .p-button:enabled:active {
  background: #7e22ce;
  color: #ffffff;
  border-color: #7e22ce;
}

.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: transparent;
  color: #a855f7;
  border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
  background: rgba(168, 85, 247, 0.04);
  color: #a855f7;
  border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(168, 85, 247, 0.16);
  color: #a855f7;
  border: 1px solid;
}

.p-button.p-button-help.p-button-text,
.p-buttonset.p-button-help > .p-button.p-button-text,
.p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: transparent;
  color: #a855f7;
  border-color: transparent;
}

.p-button.p-button-help.p-button-text:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
  background: rgba(168, 85, 247, 0.04);
  border-color: transparent;
  color: #a855f7;
}

.p-button.p-button-help.p-button-text:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
  background: rgba(168, 85, 247, 0.16);
  border-color: transparent;
  color: #a855f7;
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  color: #ffffff;
  background: #ef4444;
  border: 1px solid #ef4444;
}

.p-button.p-button-danger:enabled:hover,
.p-buttonset.p-button-danger > .p-button:enabled:hover,
.p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: #dc2626;
  color: #ffffff;
  border-color: #dc2626;
}

.p-button.p-button-danger:enabled:focus,
.p-buttonset.p-button-danger > .p-button:enabled:focus,
.p-splitbutton.p-button-danger > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #fecaca;
}

.p-button.p-button-danger:enabled:active,
.p-buttonset.p-button-danger > .p-button:enabled:active,
.p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: #b91c1c;
  color: #ffffff;
  border-color: #b91c1c;
}

.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: #ef4444;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: rgba(239, 68, 68, 0.04);
  color: #ef4444;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(239, 68, 68, 0.16);
  color: #ef4444;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger > .p-button.p-button-text,
.p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: #ef4444;
  border-color: transparent;
}

.p-button.p-button-danger.p-button-text:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: rgba(239, 68, 68, 0.04);
  border-color: transparent;
  color: #ef4444;
}

.p-button.p-button-danger.p-button-text:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: rgba(239, 68, 68, 0.16);
  border-color: transparent;
  color: #ef4444;
}

.p-button.p-button-link {
  color: #1d4ed8;
  background: transparent;
  border: transparent;

  &:enabled {
    &:hover {
      background: transparent;
      color: #1d4ed8;
      border-color: transparent;

      .p-button-label {
        text-decoration: underline;
      }
    }

    &:focus {
      background: transparent;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
      border-color: transparent;
    }

    &:active {
      background: transparent;
      color: #1d4ed8;
      border-color: transparent;
    }
  }
}

.p-speeddial-button.p-button.p-button-icon-only {
  width: 4rem;
  height: 4rem;

  .p-button-icon {
    font-size: 1.3rem;
  }
}

.p-speeddial-action {
  width: 3rem;
  height: 3rem;
  background: #495057;
  color: #fff;

  &:hover {
    background: #022354;
    color: #fff;
  }
}

.p-speeddial-direction-up .p-speeddial-item {
  margin: 0.25rem 0;

  &:first-child {
    margin-bottom: 0.5rem;
  }
}

.p-speeddial-direction-down .p-speeddial-item {
  margin: 0.25rem 0;

  &:first-child {
    margin-top: 0.5rem;
  }
}

.p-speeddial-direction-left .p-speeddial-item {
  margin: 0 0.25rem;

  &:first-child {
    margin-right: 0.5rem;
  }
}

.p-speeddial-direction-right .p-speeddial-item {
  margin: 0 0.25rem;

  &:first-child {
    margin-left: 0.5rem;
  }
}

.p-speeddial-circle .p-speeddial-item,
.p-speeddial-semi-circle .p-speeddial-item,
.p-speeddial-quarter-circle .p-speeddial-item {
  margin: 0;
}

.p-speeddial-circle .p-speeddial-item {
  &:first-child,
  &:last-child {
    margin: 0;
  }
}

.p-speeddial-semi-circle .p-speeddial-item {
  &:first-child,
  &:last-child {
    margin: 0;
  }
}

.p-speeddial-quarter-circle .p-speeddial-item {
  &:first-child,
  &:last-child {
    margin: 0;
  }
}

.p-speeddial-mask {
  background-color: rgba(0, 0, 0, 0.4);
}

.p-splitbutton {
  border-radius: 6px;

  &.p-button-outlined {
    > .p-button {
      background-color: transparent;
      color: #1b234e;
      border: 1px solid;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: rgba(59, 130, 246, 0.04);
        color: #1b234e;
      }

      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: rgba(59, 130, 246, 0.16);
        color: #1b234e;
      }
    }

    &.p-button-plain > .p-button {
      color: #6c757d;
      border-color: #6c757d;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: #e9ecef;
        color: #6c757d;
      }

      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: #dee2e6;
        color: #6c757d;
      }
    }
  }

  &.p-button-text {
    > .p-button {
      background-color: transparent;
      color: #1b234e;
      border-color: transparent;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: rgba(59, 130, 246, 0.04);
        color: #1b234e;
        border-color: transparent;
      }

      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: rgba(59, 130, 246, 0.16);
        color: #1b234e;
        border-color: transparent;
      }
    }

    &.p-button-plain > .p-button {
      color: #6c757d;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: #e9ecef;
        color: #6c757d;
      }

      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: #dee2e6;
        color: #6c757d;
      }
    }
  }

  &.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  &.p-button-rounded {
    border-radius: 2rem;

    > .p-button {
      border-radius: 2rem;
    }
  }

  &.p-button-sm > .p-button {
    font-size: 0.875rem;
    padding: 0.65625rem 1.09375rem;

    .p-button-icon {
      font-size: 0.875rem;
    }
  }

  &.p-button-lg > .p-button {
    font-size: 1.25rem;
    padding: 0.9375rem 1.5625rem;

    .p-button-icon {
      font-size: 1.25rem;
    }
  }

  &.p-button-secondary {
    &.p-button-outlined > .p-button {
      background-color: transparent;
      color: #64748b;
      border: 1px solid;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: rgba(100, 116, 139, 0.04);
        color: #64748b;
      }

      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: rgba(100, 116, 139, 0.16);
        color: #64748b;
      }
    }

    &.p-button-text > .p-button {
      background-color: transparent;
      color: #64748b;
      border-color: transparent;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: rgba(100, 116, 139, 0.04);
        border-color: transparent;
        color: #64748b;
      }

      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: rgba(100, 116, 139, 0.16);
        border-color: transparent;
        color: #64748b;
      }
    }
  }

  &.p-button-info {
    &.p-button-outlined > .p-button {
      background-color: transparent;
      color: #1b234e;
      border: 1px solid;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: rgba(59, 130, 246, 0.04);
        color: #1b234e;
      }

      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: rgba(59, 130, 246, 0.16);
        color: #1b234e;
      }
    }

    &.p-button-text > .p-button {
      background-color: transparent;
      color: #1b234e;
      border-color: transparent;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: rgba(59, 130, 246, 0.04);
        border-color: transparent;
        color: #1b234e;
      }

      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: rgba(59, 130, 246, 0.16);
        border-color: transparent;
        color: #1b234e;
      }
    }
  }

  &.p-button-success {
    &.p-button-outlined > .p-button {
      background-color: transparent;
      color: #22c55e;
      border: 1px solid;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: rgba(34, 197, 94, 0.04);
        color: #22c55e;
      }

      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: rgba(34, 197, 94, 0.16);
        color: #22c55e;
      }
    }

    &.p-button-text > .p-button {
      background-color: transparent;
      color: #22c55e;
      border-color: transparent;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: rgba(34, 197, 94, 0.04);
        border-color: transparent;
        color: #22c55e;
      }

      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: rgba(34, 197, 94, 0.16);
        border-color: transparent;
        color: #22c55e;
      }
    }
  }

  &.p-button-warning {
    &.p-button-outlined > .p-button {
      background-color: transparent;
      color: #f59e0b;
      border: 1px solid;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: rgba(245, 158, 11, 0.04);
        color: #f59e0b;
      }

      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: rgba(245, 158, 11, 0.16);
        color: #f59e0b;
      }
    }

    &.p-button-text > .p-button {
      background-color: transparent;
      color: #f59e0b;
      border-color: transparent;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: rgba(245, 158, 11, 0.04);
        border-color: transparent;
        color: #f59e0b;
      }

      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: rgba(245, 158, 11, 0.16);
        border-color: transparent;
        color: #f59e0b;
      }
    }
  }

  &.p-button-help {
    &.p-button-outlined > .p-button {
      background-color: transparent;
      color: #a855f7;
      border: 1px solid;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: rgba(168, 85, 247, 0.04);
        color: #a855f7;
      }

      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: rgba(168, 85, 247, 0.16);
        color: #a855f7;
      }
    }

    &.p-button-text > .p-button {
      background-color: transparent;
      color: #a855f7;
      border-color: transparent;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: rgba(168, 85, 247, 0.04);
        border-color: transparent;
        color: #a855f7;
      }

      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: rgba(168, 85, 247, 0.16);
        border-color: transparent;
        color: #a855f7;
      }
    }
  }

  &.p-button-danger {
    &.p-button-outlined > .p-button {
      background-color: transparent;
      color: #ef4444;
      border: 1px solid;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: rgba(239, 68, 68, 0.04);
        color: #ef4444;
      }

      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: rgba(239, 68, 68, 0.16);
        color: #ef4444;
      }
    }

    &.p-button-text > .p-button {
      background-color: transparent;
      color: #ef4444;
      border-color: transparent;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: rgba(239, 68, 68, 0.04);
        border-color: transparent;
        color: #ef4444;
      }

      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: rgba(239, 68, 68, 0.16);
        border-color: transparent;
        color: #ef4444;
      }
    }
  }
}

.p-carousel {
  .p-carousel-content {
    .p-carousel-prev,
    .p-carousel-next {
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      margin: 0.5rem;
    }

    .p-carousel-prev:enabled:hover,
    .p-carousel-next:enabled:hover {
      color: #343a40;
      border-color: transparent;
      background: #e9ecef;
    }

    .p-carousel-prev:focus,
    .p-carousel-next:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
  }

  .p-carousel-indicators {
    padding: 1rem;

    .p-carousel-indicator {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;

      button {
        background-color: #ced4da;
        width: 2rem;
        height: 0.5rem;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 0;

        &:hover {
          background: #adb5bd;
        }
      }

      &.p-highlight button {
        background: #eff6ff;
        color: #1d4ed8;
      }
    }
  }
}

.p-datatable {
  border: 1px solid #e2e8f0;
  border-radius: 5px;

  .p-paginator-top,
  .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }

  .p-datatable-header {
    background: #f8f9fa;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 700;
  }

  .p-datatable-footer {
    background: #f8f9fa;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 700;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    font-weight: 100;
    color: #475569;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
  }

  .p-datatable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    font-weight: 700;
    color: #343a40;
    background: #f8f9fa;
  }

  .p-sortable-column {
    .p-sortable-column-icon {
      color: #343a40;
      margin-left: 0.5rem;
    }

    .p-sortable-column-badge {
      border-radius: 50%;
      height: 1.143rem;
      min-width: 1.143rem;
      line-height: 1.143rem;
      color: #1d4ed8;
      background: #eff6ff;
      margin-left: 0.5rem;
    }

    &:not(.p-highlight):hover {
      background: #e9ecef;
      color: #343a40;

      .p-sortable-column-icon {
        color: #343a40;
      }
    }

    &.p-highlight {
      background: #eff6ff;
      color: #1d4ed8;

      .p-sortable-column-icon {
        color: #1d4ed8;
      }

      &:hover {
        background: #eff6ff;
        color: #1d4ed8;

        .p-sortable-column-icon {
          color: #1d4ed8;
        }
      }
    }

    &:focus {
      box-shadow: inset 0 0 0 0.15rem #bfdbfe;
      outline: 0 none;
    }
  }

  .p-datatable-tbody > tr {
    background: #ffffff;
    color: #495057;
    transition: box-shadow 0.2s;

    > td {
      text-align: left;
      border: 1px solid #dee2e6;
      border-width: 0 0 1px 0;
      padding: 1rem 1rem;

      .p-row-toggler,
      .p-row-editor-init,
      .p-row-editor-save,
      .p-row-editor-cancel {
        width: 2rem;
        height: 2rem;
        color: #6c757d;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      }

      .p-row-toggler:enabled:hover,
      .p-row-editor-init:enabled:hover,
      .p-row-editor-save:enabled:hover,
      .p-row-editor-cancel:enabled:hover {
        color: #343a40;
        border-color: transparent;
        background: #e9ecef;
      }

      .p-row-toggler:focus,
      .p-row-editor-init:focus,
      .p-row-editor-save:focus,
      .p-row-editor-cancel:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
      }

      .p-row-editor-save {
        margin-right: 0.5rem;
      }
    }

    &:focus {
      outline: 0.15rem solid #bfdbfe;
      outline-offset: 0.15rem;
    }

    &.p-highlight {
      background: #eff6ff;
      color: #1d4ed8;
    }

    &.p-datatable-dragpoint-top > td {
      box-shadow: inset 0 2px 0 0 #eff6ff;
    }

    &.p-datatable-dragpoint-bottom > td {
      box-shadow: inset 0 -2px 0 0 #eff6ff;
    }
  }

  &.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
  }

  .p-column-resizer-helper {
    background: #1b234e;
  }

  .p-datatable-scrollable-header,
  .p-datatable-scrollable-footer {
    background: #f8f9fa;
  }

  &.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > {
    .p-datatable-thead,
    .p-datatable-tfoot {
      background-color: #f8f9fa;
    }
  }

  .p-datatable-loading-icon {
    font-size: 2rem;
  }

  &.p-datatable-gridlines {
    .p-datatable-header {
      border-width: 1px 1px 0 1px;
    }

    .p-datatable-footer {
      border-width: 0 1px 1px 1px;
    }

    .p-paginator-top {
      border-width: 0 1px 0 1px;
    }

    .p-paginator-bottom {
      border-width: 0 1px 1px 1px;
    }

    .p-datatable-thead > tr > th {
      border-width: 1px 1px 1px 1px;
    }

    .p-datatable-tbody > tr > td,
    .p-datatable-tfoot > tr > td {
      border-width: 1px;
    }
  }

  &.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    background: #fcfcfc;

    &.p-highlight {
      background: #eff6ff;
      color: #1d4ed8;

      .p-row-toggler {
        color: #1d4ed8;

        &:hover {
          color: #1d4ed8;
        }
      }
    }
  }

  &.p-datatable-sm {
    .p-datatable-header,
    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr > td,
    .p-datatable-tfoot > tr > td,
    .p-datatable-footer {
      padding: 0.5rem 0.5rem;
    }
  }

  &.p-datatable-lg {
    .p-datatable-header,
    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr > td,
    .p-datatable-tfoot > tr > td,
    .p-datatable-footer {
      padding: 1.25rem 1.25rem;
    }
  }
}

.p-dataview {
  .p-paginator-top,
  .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }

  .p-dataview-header {
    background: #f8f9fa;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 700;
  }

  .p-dataview-content {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    padding: 0;
  }

  &.p-dataview-list .p-dataview-content > .p-grid > div {
    border: solid #dee2e6;
    border-width: 0 0 1px 0;
  }

  .p-dataview-footer {
    background: #f8f9fa;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 700;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .p-dataview-loading-icon {
    font-size: 2rem;
  }

  .p-dataview-emptymessage {
    padding: 1.25rem;
  }
}

.p-column-filter-row {
  .p-column-filter-menu-button,
  .p-column-filter-clear-button {
    margin-left: 0.5rem;
  }
}

.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

  &:hover {
    color: #343a40;
    border-color: transparent;
    background: #e9ecef;
  }

  &.p-column-filter-menu-button-open {
    background: #e9ecef;
    color: #343a40;

    &:hover {
      background: #e9ecef;
      color: #343a40;
    }
  }

  &.p-column-filter-menu-button-active {
    background: #eff6ff;
    color: #1d4ed8;

    &:hover {
      background: #eff6ff;
      color: #1d4ed8;
    }
  }

  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfdbfe;
  }
}

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

  &:hover {
    color: #343a40;
    border-color: transparent;
    background: #e9ecef;
  }

  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfdbfe;
  }
}

.p-column-filter-overlay {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  min-width: 12.5rem;

  .p-column-filter-row-items {
    padding: 0.75rem 0;

    .p-column-filter-row-item {
      margin: 0;
      padding: 0.75rem 1.25rem;
      border: 0 none;
      color: #495057;
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;

      &.p-highlight {
        color: #1d4ed8;
        background: #eff6ff;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        color: #495057;
        background: #e9ecef;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem #bfdbfe;
      }
    }

    .p-column-filter-separator {
      border-top: 1px solid #dee2e6;
      margin: 0.25rem 0;
    }
  }
}

.p-column-filter-overlay-menu {
  .p-column-filter-operator {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #dee2e6;
    color: #343a40;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  .p-column-filter-constraint {
    padding: 1.25rem;
    border-bottom: 1px solid #dee2e6;

    .p-column-filter-matchmode-dropdown {
      margin-bottom: 0.5rem;
    }

    .p-column-filter-remove-button {
      margin-top: 0.5rem;
    }

    &:last-child {
      border-bottom: 0 none;
    }
  }

  .p-column-filter-add-rule {
    padding: 0.75rem 1.25rem;
  }

  .p-column-filter-buttonbar {
    padding: 1.25rem;
  }
}

.fc {
  /* FullCalendar 4 */
  /* FullCalendar 5 */

  &.fc-unthemed {
    .fc-view-container {
      th {
        background: #f8f9fa;
        border: 1px solid #dee2e6;
        color: #343a40;
      }

      td {
        &.fc-widget-content {
          background: #ffffff;
          border: 1px solid #dee2e6;
          color: #495057;
        }

        &.fc-head-container {
          border: 1px solid #dee2e6;
        }
      }

      .fc-row {
        border-right: 1px solid #dee2e6;
      }

      .fc-event {
        background: #2563eb;
        border: 1px solid #2563eb;
        color: #ffffff;
      }

      .fc-divider {
        background: #f8f9fa;
        border: 1px solid #dee2e6;
      }
    }

    .fc-toolbar {
      .fc-button {
        color: #ffffff;
        background: #1b234e;
        border: 1px solid #1b234e;
        font-size: 1rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 6px;
        display: flex;
        align-items: center;

        &:enabled {
          &:hover {
            background: #2563eb;
            color: #ffffff;
            border-color: #2563eb;
          }

          &:active {
            background: #1d4ed8;
            color: #ffffff;
            border-color: #1d4ed8;

            &:focus {
              outline: 0 none;
              outline-offset: 0;
              box-shadow: 0 0 0 0.2rem #bfdbfe;
            }
          }
        }

        .fc-icon-chevron-left {
          font-family: 'PrimeIcons' !important;
          text-indent: 0;
          font-size: 1rem;

          &:before {
            content: '\e900';
          }
        }

        .fc-icon-chevron-right {
          font-family: 'PrimeIcons' !important;
          text-indent: 0;
          font-size: 1rem;

          &:before {
            content: '\e901';
          }
        }

        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #bfdbfe;
        }

        &.fc-dayGridMonth-button,
        &.fc-timeGridWeek-button,
        &.fc-timeGridDay-button {
          background: #ffffff;
          border: 1px solid #ced4da;
          color: #495057;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        }

        &.fc-dayGridMonth-button:hover,
        &.fc-timeGridWeek-button:hover,
        &.fc-timeGridDay-button:hover {
          background: #e9ecef;
          border-color: #ced4da;
          color: #495057;
        }

        &.fc-dayGridMonth-button.fc-button-active,
        &.fc-timeGridWeek-button.fc-button-active,
        &.fc-timeGridDay-button.fc-button-active {
          background: #1b234e;
          border-color: #1b234e;
          color: #ffffff;
        }

        &.fc-dayGridMonth-button.fc-button-active:hover,
        &.fc-timeGridWeek-button.fc-button-active:hover,
        &.fc-timeGridDay-button.fc-button-active:hover {
          background: #2563eb;
          border-color: #2563eb;
          color: #ffffff;
        }

        &.fc-dayGridMonth-button:focus,
        &.fc-timeGridWeek-button:focus,
        &.fc-timeGridDay-button:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #bfdbfe;
          z-index: 1;
        }
      }

      .fc-button-group .fc-button {
        border-radius: 0;

        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }

  &.fc-theme-standard {
    .fc-view-harness {
      .fc-scrollgrid {
        border-color: #dee2e6;
      }

      th {
        background: #f8f9fa;
        border-color: #dee2e6;
        color: #343a40;
      }

      td {
        color: #495057;
        border-color: #dee2e6;
      }

      .fc-view {
        background: #ffffff;
      }

      .fc-popover {
        background: none;
        border: 0 none;

        .fc-popover-header {
          border: 1px solid #dee2e6;
          padding: 1.25rem;
          background: #f8f9fa;
          color: #343a40;

          .fc-popover-close {
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            font-family: 'PrimeIcons' !important;
            font-size: 1rem;
            width: 2rem;
            height: 2rem;
            color: #6c757d;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

            &:before {
              content: '\e90b';
            }

            &:enabled:hover {
              color: #343a40;
              border-color: transparent;
              background: #e9ecef;
            }

            &:focus {
              outline: 0 none;
              outline-offset: 0;
              box-shadow: 0 0 0 0.2rem #bfdbfe;
            }
          }
        }

        .fc-popover-body {
          padding: 1.25rem;
          border: 1px solid #dee2e6;
          background: #ffffff;
          color: #495057;
          border-top: 0 none;
        }
      }

      .fc-event {
        &.fc-daygrid-block-event {
          color: #ffffff;
          background: #2563eb;
          border-color: #2563eb;

          .fc-event-main {
            color: #ffffff;
          }
        }

        &.fc-daygrid-dot-event {
          .fc-daygrid-event-dot {
            background: #2563eb;
            border-color: #2563eb;
          }

          &:hover {
            background: #e9ecef;
            color: #495057;
          }
        }
      }

      .fc-cell-shaded {
        background: #f8f9fa;
      }
    }

    .fc-toolbar {
      .fc-button {
        color: #ffffff;
        background: #1b234e;
        border: 1px solid #1b234e;
        font-size: 1rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 6px;

        &:enabled {
          &:hover {
            background: #2563eb;
            color: #ffffff;
            border-color: #2563eb;
          }

          &:active {
            background: #1d4ed8;
            color: #ffffff;
            border-color: #1d4ed8;

            &:focus {
              outline: 0 none;
              outline-offset: 0;
              box-shadow: 0 0 0 0.2rem #bfdbfe;
            }
          }
        }

        &:disabled {
          opacity: 0.6;
          color: #ffffff;
          background: #1b234e;
          border: 1px solid #1b234e;
        }

        .fc-icon-chevron-left {
          font-family: 'PrimeIcons' !important;
          text-indent: 0;
          font-size: 1rem;

          &:before {
            content: '\e900';
          }
        }

        .fc-icon-chevron-right {
          font-family: 'PrimeIcons' !important;
          text-indent: 0;
          font-size: 1rem;

          &:before {
            content: '\e901';
          }
        }

        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #bfdbfe;
        }

        &.fc-dayGridMonth-button,
        &.fc-timeGridWeek-button,
        &.fc-timeGridDay-button {
          background: #ffffff;
          border: 1px solid #ced4da;
          color: #495057;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        }

        &.fc-dayGridMonth-button:hover,
        &.fc-timeGridWeek-button:hover,
        &.fc-timeGridDay-button:hover {
          background: #e9ecef;
          border-color: #ced4da;
          color: #495057;
        }

        &.fc-dayGridMonth-button.fc-button-active,
        &.fc-timeGridWeek-button.fc-button-active,
        &.fc-timeGridDay-button.fc-button-active {
          background: #1b234e;
          border-color: #1b234e;
          color: #ffffff;
        }

        &.fc-dayGridMonth-button.fc-button-active:hover,
        &.fc-timeGridWeek-button.fc-button-active:hover,
        &.fc-timeGridDay-button.fc-button-active:hover {
          background: #2563eb;
          border-color: #2563eb;
          color: #ffffff;
        }

        &.fc-dayGridMonth-button:not(:disabled):focus,
        &.fc-timeGridWeek-button:not(:disabled):focus,
        &.fc-timeGridDay-button:not(:disabled):focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #bfdbfe;
          z-index: 1;
        }
      }

      .fc-button-group .fc-button {
        border-radius: 0;

        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }

    a {
      color: #495057;
    }

    .fc-highlight {
      color: #1d4ed8;
      background: #eff6ff;
    }
  }
}

.p-orderlist {
  .p-orderlist-controls {
    padding: 1.25rem;

    .p-button {
      margin-bottom: 0.5rem;
    }
  }

  .p-orderlist-header {
    background: #f8f9fa;
    color: #343a40;
    border: 1px solid #dee2e6;
    padding: 1.25rem;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .p-orderlist-title {
      font-weight: 700;
    }
  }

  .p-orderlist-filter-container {
    padding: 1.25rem;
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-bottom: 0 none;

    .p-orderlist-filter-input {
      padding-right: 1.75rem;
    }

    .p-orderlist-filter-icon {
      right: 0.75rem;
      color: #6c757d;
    }
  }

  .p-orderlist-list {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    padding: 0.75rem 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    .p-orderlist-item {
      padding: 0.75rem 1.25rem;
      margin: 0;
      border: 0 none;
      color: #495057;
      background: transparent;
      transition: box-shadow 0.2s;

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem #bfdbfe;
      }

      &.p-highlight {
        color: #1d4ed8;
        background: #eff6ff;
      }
    }

    .p-orderlist-empty-message {
      padding: 0.75rem 1.25rem;
      color: #495057;
    }

    &:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
      background: #e9ecef;
      color: #495057;
    }
  }

  &.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even) {
    background: #fcfcfc;

    &:hover {
      background: #e9ecef;
    }
  }
}

.p-orderlist-item.cdk-drag-preview {
  padding: 0.75rem 1.25rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 0 none;
  color: #495057;
  background: #ffffff;
  margin: 0;
}

.p-organizationchart {
  .p-organizationchart-node-content {
    &.p-organizationchart-selectable-node:not(.p-highlight):hover {
      background: #e9ecef;
      color: #495057;
    }

    &.p-highlight {
      background: #eff6ff;
      color: #1d4ed8;

      .p-node-toggler i {
        color: #70aeff;
      }
    }
  }

  .p-organizationchart-line-down {
    background: #dee2e6;
  }

  .p-organizationchart-line-left {
    border-right: 1px solid #dee2e6;
    border-color: #dee2e6;
  }

  .p-organizationchart-line-top {
    border-top: 1px solid #dee2e6;
    border-color: #dee2e6;
  }

  .p-organizationchart-node-content {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    padding: 1.25rem;

    .p-node-toggler {
      background: inherit;
      color: inherit;
      border-radius: 50%;

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
      }
    }
  }
}

.p-paginator {
  background: #ffffff;
  color: #6c757d;
  border: solid #e9ecef;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 6px;

  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 50%;
  }

  .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
    border-color: transparent;
    color: #343a40;
  }

  .p-paginator-first {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .p-paginator-last {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .p-dropdown {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 3rem;

    .p-dropdown-label {
      padding-right: 0;
    }
  }

  .p-paginator-page-input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    .p-inputtext {
      max-width: 3rem;
    }
  }

  .p-paginator-current {
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    padding: 0 0.5rem;
  }

  .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 50%;

    &.p-highlight {
      background: #eff6ff;
      border-color: #eff6ff;
      color: #1d4ed8;
    }

    &:not(.p-highlight):hover {
      background: #e9ecef;
      border-color: transparent;
      color: #343a40;
    }
  }
}

.p-picklist {
  .p-picklist-buttons {
    padding: 1.25rem;

    .p-button {
      margin-bottom: 0.5rem;
    }
  }

  .p-picklist-header {
    background: #f8f9fa;
    color: #343a40;
    border: 1px solid #dee2e6;
    padding: 1.25rem;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .p-picklist-title {
      font-weight: 700;
    }
  }

  .p-picklist-filter-container {
    padding: 1.25rem;
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-bottom: 0 none;

    .p-picklist-filter-input {
      padding-right: 1.75rem;
    }

    .p-picklist-filter-icon {
      right: 0.75rem;
      color: #6c757d;
    }
  }

  .p-picklist-list {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    padding: 0.75rem 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    .p-picklist-item {
      padding: 0.75rem 1.25rem;
      margin: 0;
      border: 0 none;
      color: #495057;
      background: transparent;
      transition: box-shadow 0.2s;

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem #bfdbfe;
      }

      &.p-highlight {
        color: #1d4ed8;
        background: #eff6ff;
      }
    }

    .p-picklist-empty-message {
      padding: 0.75rem 1.25rem;
      color: #495057;
    }

    &:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
      background: #e9ecef;
      color: #495057;
    }
  }

  &.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even) {
    background: #fcfcfc;

    &:hover {
      background: #e9ecef;
    }
  }
}

.p-picklist-item.cdk-drag-preview {
  padding: 0.75rem 1.25rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 0 none;
  color: #495057;
  background: #ffffff;
  margin: 0;
}

.p-timeline {
  .p-timeline-event-marker {
    border: 2px solid #1b234e;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: #ffffff;
  }

  .p-timeline-event-connector {
    background-color: #dee2e6;
  }

  &.p-timeline-vertical {
    .p-timeline-event-opposite,
    .p-timeline-event-content {
      padding: 0 1rem;
    }

    .p-timeline-event-connector {
      width: 2px;
    }
  }

  &.p-timeline-horizontal {
    .p-timeline-event-opposite,
    .p-timeline-event-content {
      padding: 1rem 0;
    }

    .p-timeline-event-connector {
      height: 2px;
    }
  }
}

.p-tree {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 1.25rem;
  border-radius: 6px;

  .p-tree-container .p-treenode {
    padding: 0.143rem;

    .p-treenode-content {
      border-radius: 6px;
      transition: box-shadow 0.2s;
      padding: 0.5rem;

      .p-tree-toggler {
        margin-right: 0.5rem;
        width: 2rem;
        height: 2rem;
        color: #6c757d;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

        &:enabled:hover {
          color: #343a40;
          border-color: transparent;
          background: #e9ecef;
        }

        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #bfdbfe;
        }
      }

      .p-treenode-icon {
        margin-right: 0.5rem;
        color: #6c757d;
      }

      .p-checkbox {
        margin-right: 0.5rem;

        .p-indeterminate .p-checkbox-icon {
          color: #495057;
        }
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem #bfdbfe;
      }

      &.p-highlight {
        background: #eff6ff;
        color: #1d4ed8;

        .p-tree-toggler,
        .p-treenode-icon,
        .p-tree-toggler:hover,
        .p-treenode-icon:hover {
          color: #1d4ed8;
        }
      }

      &.p-treenode-selectable:not(.p-highlight):hover,
      &.p-treenode-dragover {
        background: #e9ecef;
        color: #495057;
      }
    }
  }

  .p-tree-filter-container {
    margin-bottom: 0.5rem;

    .p-tree-filter {
      width: 100%;
      padding-right: 1.75rem;
    }

    .p-tree-filter-icon {
      right: 0.75rem;
      color: #6c757d;
    }
  }

  .p-treenode-children {
    padding: 0 0 0 1rem;
  }

  .p-tree-loading-icon {
    font-size: 2rem;
  }

  .p-treenode-droppoint.p-treenode-droppoint-active {
    background-color: #8cbeff;
  }

  &.p-tree-horizontal .p-treenode .p-treenode-content {
    border-radius: 6px;
    border: 1px solid #dee2e6;
    background-color: #ffffff;
    color: #495057;
    padding: 0.5rem;
    transition: box-shadow 0.2s;

    &.p-highlight {
      background-color: #eff6ff;
      color: #1d4ed8;

      .p-treenode-icon {
        color: #1d4ed8;
      }
    }

    .p-tree-toggler {
      margin-right: 0.5rem;
    }

    .p-treenode-icon {
      color: #6c757d;
      margin-right: 0.5rem;
    }

    .p-checkbox {
      margin-right: 0.5rem;
    }

    .p-treenode-label:not(.p-highlight):hover {
      background-color: inherit;
      color: inherit;
    }

    &.p-treenode-selectable:not(.p-highlight):hover {
      background: #e9ecef;
      color: #495057;
    }

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
  }
}

.p-treetable {
  .p-paginator-top,
  .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }

  .p-treetable-header {
    background: #f8f9fa;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 700;
  }

  .p-treetable-footer {
    background: #f8f9fa;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 700;
  }

  .p-treetable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    font-weight: 700;
    color: #343a40;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
  }

  .p-treetable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    font-weight: 700;
    color: #343a40;
    background: #f8f9fa;
  }

  .p-sortable-column {
    outline-color: #bfdbfe;

    .p-sortable-column-icon {
      color: #343a40;
      margin-left: 0.5rem;
    }

    .p-sortable-column-badge {
      border-radius: 50%;
      height: 1.143rem;
      min-width: 1.143rem;
      line-height: 1.143rem;
      color: #1d4ed8;
      background: #eff6ff;
      margin-left: 0.5rem;
    }

    &:not(.p-highlight):hover {
      background: #e9ecef;
      color: #343a40;

      .p-sortable-column-icon {
        color: #343a40;
      }
    }

    &.p-highlight {
      background: #eff6ff;
      color: #1d4ed8;

      .p-sortable-column-icon {
        color: #1d4ed8;
      }
    }
  }

  .p-treetable-tbody > tr {
    background: #ffffff;
    color: #495057;
    transition: box-shadow 0.2s;

    > td {
      text-align: left;
      border: 1px solid #dee2e6;
      border-width: 0 0 1px 0;
      padding: 1rem 1rem;

      .p-treetable-toggler {
        width: 2rem;
        height: 2rem;
        color: #6c757d;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        margin-right: 0.5rem;

        &:enabled:hover {
          color: #343a40;
          border-color: transparent;
          background: #e9ecef;
        }

        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #bfdbfe;
        }
      }

      p-treetablecheckbox .p-checkbox {
        margin-right: 0.5rem;

        .p-indeterminate .p-checkbox-icon {
          color: #495057;
        }
      }
    }

    &:focus {
      outline: 0.15rem solid #bfdbfe;
      outline-offset: -0.15rem;
    }

    &.p-highlight {
      background: #eff6ff;
      color: #1d4ed8;

      .p-treetable-toggler {
        color: #1d4ed8;

        &:hover {
          color: #1d4ed8;
        }
      }
    }
  }

  &.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;

    .p-treetable-toggler {
      color: #495057;
    }
  }

  .p-column-resizer-helper {
    background: #1b234e;
  }

  .p-treetable-scrollable-header,
  .p-treetable-scrollable-footer {
    background: #f8f9fa;
  }

  .p-treetable-loading-icon {
    font-size: 2rem;
  }

  &.p-treetable-gridlines {
    .p-datatable-header {
      border-width: 1px 1px 0 1px;
    }

    .p-treetable-footer {
      border-width: 0 1px 1px 1px;
    }

    .p-treetable-top {
      border-width: 0 1px 0 1px;
    }

    .p-treetable-bottom {
      border-width: 0 1px 1px 1px;
    }

    .p-treetable-thead > tr > th,
    .p-treetable-tbody > tr > td,
    .p-treetable-tfoot > tr > td {
      border-width: 1px;
    }
  }

  &.p-treetable-sm {
    .p-treetable-header {
      padding: 0.875rem 0.875rem;
    }

    .p-treetable-thead > tr > th,
    .p-treetable-tbody > tr > td,
    .p-treetable-tfoot > tr > td,
    .p-treetable-footer {
      padding: 0.5rem 0.5rem;
    }
  }

  &.p-treetable-lg {
    .p-treetable-header,
    .p-treetable-thead > tr > th,
    .p-treetable-tbody > tr > td,
    .p-treetable-tfoot > tr > td,
    .p-treetable-footer {
      padding: 1.25rem 1.25rem;
    }
  }
}

.p-virtualscroller {
  .p-virtualscroller-header {
    background: #f8f9fa;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 700;
  }

  .p-virtualscroller-content {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    padding: 0;
  }

  .p-virtualscroller-footer {
    background: #f8f9fa;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 700;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.p-accordion {
  .p-accordion-header {
    .p-accordion-header-link {
      padding: 0.75rem;
      border: 1px solid #dee2e6;
      color: #0f172a;
      background: #f8f9fa;
      font-weight: 700;
      border-radius: 6px;
      transition: box-shadow 0.2s;

      .p-accordion-toggle-icon {
        display: none;
      }
    }

    &:not(.p-disabled) .p-accordion-header-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
    }

    &:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
      background: #e9ecef;
      border-color: #dee2e6;
      color: #343a40;
    }

    &:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        background: #f8f9fa;
        border-color: #dee2e6;
      }

      &:hover .p-accordion-header-link {
        border-color: #dee2e6;
        background: #e9ecef;
        color: #343a40;
      }
    }
  }

  .p-accordion-content {
    background: #ffffff;
    color: #495057;
  }

  p-accordiontab .p-accordion-tab {
    margin-bottom: 4px;
  }
}

.p-card {
  background: #f1f7ff;
  color: #495057;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;

  .p-card-body {
    padding: 1.25rem;
    width: 28rem;
    height: 19.7rem;
  }

  .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    text-align: center;
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  .p-card-subtitle {
    color: #6c757d;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 100;
    color: #000;
    margin: 0 2.4rem 0.3rem;
    font-size: 1rem;
  }

  .p-card-content {
    padding: 1.25rem 0;
  }

  .p-card-footer {
    padding: 1.25rem 0 0 0;
  }
}

.p-divider {
  .p-divider-content {
    background-color: #ffffff;
  }

  &.p-divider-horizontal {
    margin: 1.25rem 0;
    padding: 0 1.25rem;

    &:before {
      border-top: 1px #dee2e6;
    }

    .p-divider-content {
      padding: 0 0.5rem;
    }
  }

  &.p-divider-vertical {
    margin: 0 1.25rem;
    padding: 1.25rem 0;

    &:before {
      border-left: 1px #dee2e6;
    }

    .p-divider-content {
      padding: 0.5rem 0;
    }
  }
}

.p-fieldset {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-radius: 6px;

  .p-fieldset-legend {
    padding: 1.25rem;
    border: 1px solid #dee2e6;
    color: #343a40;
    background: #f8f9fa;
    font-weight: 700;
    border-radius: 6px;
  }

  &.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

    a {
      padding: 1.25rem;
      color: #343a40;
      border-radius: 6px;
      transition: box-shadow 0.2s;

      .p-fieldset-toggler {
        margin-right: 0.5rem;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
      }
    }

    &:hover {
      background: #e9ecef;
      border-color: #dee2e6;
      color: #343a40;
    }
  }

  .p-fieldset-content {
    padding: 1.25rem;
  }
}

.p-panel {
  .p-panel-header {
    border: 1px solid #dee2e6;
    padding: 1.25rem;
    background: #f8f9fa;
    color: #343a40;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .p-panel-title {
      font-weight: 700;
    }

    .p-panel-header-icon {
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

      &:enabled:hover {
        color: #343a40;
        border-color: transparent;
        background: #e9ecef;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
      }
    }
  }

  &.p-panel-toggleable .p-panel-header {
    padding: 0.75rem 1.25rem;
  }

  .p-panel-content {
    padding: 1.25rem;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top: 0 none;
  }

  .p-panel-footer {
    padding: 0.75rem 1.25rem;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-top: 0 none;
  }

  .p-panel-icons-end {
    order: 2;
    margin-left: auto;
  }

  .p-panel-icons-start {
    order: 0;
    margin-right: 0.5rem;
  }

  .p-panel-icons-center {
    order: 2;
    width: 100%;
    text-align: center;
  }
}

.p-scrollpanel .p-scrollpanel-bar {
  background: #f8f9fa;
  border: 0 none;
}

.p-splitter {
  border: 1px solid #dee2e6;
  background: #ffffff;
  border-radius: 6px;
  color: #495057;

  .p-splitter-gutter {
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    background: #f8f9fa;

    .p-splitter-gutter-handle {
      background: #dee2e6;
    }
  }

  .p-splitter-gutter-resizing {
    background: #dee2e6;
  }
}

.p-tabview {
  .p-tabview-nav {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-width: 0 0 2px 0;

    li {
      margin-right: 0;

      .p-tabview-nav-link {
        border: solid #dee2e6;
        border-width: 0 0 2px 0;
        border-color: transparent transparent #dee2e6 transparent;
        background: #ffffff;
        color: #6c757d;
        padding: 1.25rem;
        font-weight: 700;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        transition: box-shadow 0.2s;
        margin: 0 0 -2px 0;

        &:not(.p-disabled):focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.2rem #bfdbfe;
        }
      }

      &:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
        background: #ffffff;
        border-color: #adb5bd;
        color: #6c757d;
      }

      &.p-highlight .p-tabview-nav-link {
        background: #ffffff;
        border-color: #1b234e;
        color: #1b234e;
      }
    }
  }

  .p-tabview-left-icon {
    margin-right: 0.5rem;
  }

  .p-tabview-right-icon,
  .p-tabview-close {
    margin-left: 0.5rem;
  }

  .p-tabview-nav-btn.p-link {
    background: #ffffff;
    color: #1b234e;
    width: 3rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem #bfdbfe;
    }
  }

  .p-tabview-panels {
    background: #ffffff;
    padding: 1.25rem;
    border: 0 none;
    color: #495057;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

.p-toolbar {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 1.25rem;
  border-radius: 6px;

  .p-toolbar-separator {
    margin: 0 0.5rem;
  }
}

.p-confirm-popup {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);

  .p-confirm-popup-content {
    padding: 1.25rem;
  }

  .p-confirm-popup-footer {
    text-align: right;
    padding: 0.75rem 1.25rem;

    button {
      margin: 0 0.5rem 0 0;
      width: auto;

      &:last-child {
        margin: 0;
      }
    }
  }

  &:after,
  &:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
  }

  &.p-confirm-popup-flipped {
    &:after,
    &:before {
      border-top-color: #ffffff;
    }
  }

  .p-confirm-popup-icon {
    font-size: 1.5rem;
  }

  .p-confirm-popup-message {
    margin-left: 1rem;
  }
}

.p-dialog {
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: 0 none;

  .p-dialog-header {
    font-style: normal;
    border-bottom: 0 none;
    background: #f1f7ff;
    color: #343a40;
    padding: 1.5rem;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .p-dialog-title {
      font-weight: 700;
      font-size: 1.25rem;
    }

    .p-dialog-header-icon {
      width: 1.7rem;
      height: 1.7rem;
      color: #000;
      border: 2px solid black;
      background: transparent;
      border-radius: 4px;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      margin-right: 0.5rem;

      // &:enabled:hover {
      //   color: #343a40;
      //   border-color: transparent;
      //   background: #e9ecef;
      // }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 0 1.5rem 2rem 1.5rem;

    &:last-of-type {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  .p-dialog-footer {
    border-top: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 0 1.5rem 1.5rem 1.5rem;
    text-align: right;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    button {
      margin: 0 0.5rem 0 0;
      width: auto;
    }
  }

  &.p-confirm-dialog {
    .p-confirm-dialog-icon {
      font-size: 2rem;
    }

    .p-confirm-dialog-message {
      margin-left: 1rem;
    }
  }
}

.p-overlaypanel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);

  .p-overlaypanel-content {
    padding: 1.25rem;
  }

  .p-overlaypanel-close {
    background: #1b234e;
    color: #ffffff;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
    position: absolute;
    top: -1rem;
    right: -1rem;

    &:enabled:hover {
      background: #2563eb;
      color: #ffffff;
    }
  }

  &:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
  }

  &:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #f2f2f2;
  }

  &.p-overlaypanel-flipped {
    &:after,
    &:before {
      border-top-color: #ffffff;
    }
  }
}

.p-sidebar {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);

  .p-sidebar-header {
    padding: 1.25rem;

    .p-sidebar-close,
    .p-sidebar-icon {
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-sidebar-close:enabled:hover,
    .p-sidebar-icon:enabled:hover {
      color: #343a40;
      border-color: transparent;
      background: #e9ecef;
    }

    .p-sidebar-close:focus,
    .p-sidebar-icon:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
    }

    + .p-sidebar-content {
      padding-top: 0;
    }
  }

  .p-sidebar-content {
    padding: 1.25rem;
  }
}

.p-tooltip {
  .p-tooltip-text {
    background: #495057;
    color: #ffffff;
    padding: 0.75rem 0.75rem;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }

  &.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #495057;
  }

  &.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #495057;
  }

  &.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #495057;
  }

  &.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #495057;
  }
}

.p-fileupload {
  .p-fileupload-buttonbar {
    background: #f8f9fa;
    padding: 1.25rem;
    border: 1px solid #dee2e6;
    color: #343a40;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .p-button {
      margin-right: 0.5rem;

      &.p-fileupload-choose.p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
      }
    }
  }

  .p-fileupload-content {
    background: #ffffff;
    padding: 2rem 1rem;
    border: 1px solid #dee2e6;
    color: #495057;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .p-progressbar {
    height: 0.25rem;
  }

  .p-fileupload-row > div {
    padding: 1rem 1rem;
  }

  &.p-fileupload-advanced .p-message {
    margin-top: 0;
  }
}

.p-fileupload-choose:not(.p-disabled) {
  &:hover {
    background: #2563eb;
    color: #ffffff;
    border-color: #2563eb;
  }

  &:active {
    background: #1d4ed8;
    color: #ffffff;
    border-color: #1d4ed8;
  }
}

.p-breadcrumb {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 1rem;

  ul li {
    .p-menuitem-link {
      transition: box-shadow 0.2s;
      border-radius: 6px;

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
      }

      .p-menuitem-text {
        color: #495057;
      }

      .p-menuitem-icon {
        color: #6c757d;
      }
    }

    &.p-breadcrumb-chevron {
      margin: 0 0.5rem 0 0.5rem;
      color: #495057;
    }

    &:last-child {
      .p-menuitem-text {
        color: #495057;
      }

      .p-menuitem-icon {
        color: #6c757d;
      }
    }
  }
}

.p-contextmenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 12.5rem;

  .p-menuitem-link {
    padding: 0.75rem 1.25rem;
    color: #495057;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;

    .p-menuitem-text {
      color: #495057;
    }

    .p-menuitem-icon {
      color: #6c757d;
      margin-right: 0.5rem;
    }

    .p-submenu-icon {
      color: #6c757d;
    }

    &:not(.p-disabled):hover {
      background: #e9ecef;

      .p-menuitem-text {
        color: #495057;
      }

      .p-menuitem-icon,
      .p-submenu-icon {
        color: #6c757d;
      }
    }

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem #bfdbfe;
    }
  }

  .p-submenu-list {
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }

  .p-menuitem {
    margin: 0;

    &:last-child {
      margin: 0;
    }

    &.p-menuitem-active > .p-menuitem-link {
      background: #eff6ff;

      .p-menuitem-text,
      .p-menuitem-icon,
      .p-submenu-icon {
        color: #1d4ed8;
      }
    }
  }

  .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }

  .p-submenu-icon {
    font-size: 0.875rem;
  }

  .p-menuitem-badge {
    background: #1b234e;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.p-dock {
  .p-dock-list {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
  }

  .p-dock-item {
    padding: 0.5rem;
  }

  .p-dock-action {
    width: 4rem;
    height: 4rem;
  }

  &.p-dock-top {
    .p-dock-item-second-prev,
    .p-dock-item-second-next {
      margin: 0 0.9rem;
    }
  }

  &.p-dock-bottom {
    .p-dock-item-second-prev,
    .p-dock-item-second-next {
      margin: 0 0.9rem;
    }
  }

  &.p-dock-top {
    .p-dock-item-prev,
    .p-dock-item-next {
      margin: 0 1.3rem;
    }
  }

  &.p-dock-bottom {
    .p-dock-item-prev,
    .p-dock-item-next {
      margin: 0 1.3rem;
    }
  }

  &.p-dock-top .p-dock-item-current,
  &.p-dock-bottom .p-dock-item-current {
    margin: 0 1.5rem;
  }

  &.p-dock-left {
    .p-dock-item-second-prev,
    .p-dock-item-second-next {
      margin: 0.9rem 0;
    }
  }

  &.p-dock-right {
    .p-dock-item-second-prev,
    .p-dock-item-second-next {
      margin: 0.9rem 0;
    }
  }

  &.p-dock-left {
    .p-dock-item-prev,
    .p-dock-item-next {
      margin: 1.3rem 0;
    }
  }

  &.p-dock-right {
    .p-dock-item-prev,
    .p-dock-item-next {
      margin: 1.3rem 0;
    }
  }

  &.p-dock-left .p-dock-item-current,
  &.p-dock-right .p-dock-item-current {
    margin: 1.5rem 0;
  }
}

@media screen and (max-width: 960px) {
  .p-dock {
    &.p-dock-top .p-dock-list-container,
    &.p-dock-bottom .p-dock-list-container {
      overflow-x: auto;
      width: 100%;
    }

    &.p-dock-top .p-dock-list-container .p-dock-list,
    &.p-dock-bottom .p-dock-list-container .p-dock-list {
      margin: 0 auto;
    }

    &.p-dock-left .p-dock-list-container,
    &.p-dock-right .p-dock-list-container {
      overflow-y: auto;
      height: 100%;
    }

    &.p-dock-left .p-dock-list-container .p-dock-list,
    &.p-dock-right .p-dock-list-container .p-dock-list {
      margin: auto 0;
    }

    .p-dock-list .p-dock-item {
      transform: none;
      margin: 0;
    }
  }
}

.p-megamenu {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 6px;

  .p-megamenu-root-list > .p-menuitem {
    > .p-menuitem-link {
      padding: 0.75rem 1.25rem;
      color: #495057;
      border-radius: 6px;
      transition: box-shadow 0.2s;
      user-select: none;

      .p-menuitem-text {
        color: #495057;
      }

      .p-menuitem-icon {
        color: #6c757d;
        margin-right: 0.5rem;
      }

      .p-submenu-icon {
        color: #6c757d;
        margin-left: 0.5rem;
      }

      &:not(.p-disabled):hover {
        background: #e9ecef;

        .p-menuitem-text {
          color: #495057;
        }

        .p-menuitem-icon,
        .p-submenu-icon {
          color: #6c757d;
        }
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem #bfdbfe;
      }
    }

    &.p-menuitem-active > .p-menuitem-link {
      background: #eff6ff;

      &:not(.p-disabled):hover {
        background: #eff6ff;
      }

      .p-menuitem-text,
      &:not(.p-disabled):hover .p-menuitem-text,
      .p-menuitem-icon,
      &:not(.p-disabled):hover .p-menuitem-icon,
      .p-submenu-icon,
      &:not(.p-disabled):hover .p-submenu-icon {
        color: #1d4ed8;
      }
    }
  }

  .p-menuitem-link {
    padding: 0.75rem 1.25rem;
    color: #495057;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;

    .p-menuitem-text {
      color: #495057;
    }

    .p-menuitem-icon {
      color: #6c757d;
      margin-right: 0.5rem;
    }

    .p-submenu-icon {
      color: #6c757d;
    }

    &:not(.p-disabled):hover {
      background: #e9ecef;

      .p-menuitem-text {
        color: #495057;
      }

      .p-menuitem-icon,
      .p-submenu-icon {
        color: #6c757d;
      }
    }

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem #bfdbfe;
    }
  }

  .p-megamenu-panel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  .p-megamenu-submenu-header {
    margin: 0;
    padding: 0.75rem 1.25rem;
    color: #343a40;
    background: #ffffff;
    font-weight: 700;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  .p-megamenu-submenu {
    padding: 0.25rem 0;
    width: 12.5rem;

    .p-menu-separator {
      border-top: 1px solid #dee2e6;
      margin: 0.25rem 0;
    }

    .p-menuitem {
      margin: 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #eff6ff;

    .p-menuitem-text,
    .p-menuitem-icon,
    .p-submenu-icon {
      color: #1d4ed8;
    }
  }

  &.p-megamenu-vertical {
    width: 12.5rem;
    padding: 0.25rem 0;

    .p-menuitem {
      margin: 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  .p-menuitem-badge {
    background: #1b234e;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.p-menu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  width: 12.5rem;

  .p-menuitem-link {
    padding: 0.75rem 1.25rem;
    color: #495057;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;

    .p-menuitem-text {
      color: #495057;
    }

    .p-menuitem-icon {
      color: #6c757d;
      margin-right: 0.5rem;
    }

    .p-submenu-icon {
      color: #6c757d;
    }

    &:not(.p-disabled):hover {
      background: #e9ecef;

      .p-menuitem-text {
        color: #495057;
      }

      .p-menuitem-icon,
      .p-submenu-icon {
        color: #6c757d;
      }
    }

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem #bfdbfe;
    }
  }

  &.p-menu-overlay {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  .p-submenu-header {
    margin: 0;
    padding: 0.75rem 1.25rem;
    color: #343a40;
    background: #ffffff;
    font-weight: 700;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }

  .p-menuitem {
    margin: 0;

    &:last-child {
      margin: 0;
    }
  }

  .p-menuitem-badge {
    background: #1b234e;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.p-menubar {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 6px;

  .p-menuitem-link {
    padding: 0.75rem 1.25rem;
    color: #495057;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;

    .p-menuitem-text {
      color: #495057;
    }

    .p-menuitem-icon {
      color: #6c757d;
      margin-right: 0.5rem;
    }

    .p-submenu-icon {
      color: #6c757d;
    }

    &:not(.p-disabled):hover {
      background: #e9ecef;

      .p-menuitem-text {
        color: #495057;
      }

      .p-menuitem-icon,
      .p-submenu-icon {
        color: #6c757d;
      }
    }

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem #bfdbfe;
    }
  }

  .p-menubar-root-list > .p-menuitem {
    > .p-menuitem-link {
      padding: 0.75rem 1.25rem;
      color: #495057;
      border-radius: 6px;
      transition: box-shadow 0.2s;
      user-select: none;

      .p-menuitem-text {
        color: #495057;
      }

      .p-menuitem-icon {
        color: #6c757d;
        margin-right: 0.5rem;
      }

      .p-submenu-icon {
        color: #6c757d;
        margin-left: 0.5rem;
      }

      &:not(.p-disabled):hover {
        background: #e9ecef;

        .p-menuitem-text {
          color: #495057;
        }

        .p-menuitem-icon,
        .p-submenu-icon {
          color: #6c757d;
        }
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem #bfdbfe;
      }
    }

    &.p-menuitem-active > .p-menuitem-link {
      background: #eff6ff;

      &:not(.p-disabled):hover {
        background: #eff6ff;
      }

      .p-menuitem-text,
      &:not(.p-disabled):hover .p-menuitem-text,
      .p-menuitem-icon,
      &:not(.p-disabled):hover .p-menuitem-icon,
      .p-submenu-icon,
      &:not(.p-disabled):hover .p-submenu-icon {
        color: #1d4ed8;
      }
    }
  }

  .p-submenu-list {
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    width: 12.5rem;

    .p-menu-separator {
      border-top: 1px solid #dee2e6;
      margin: 0.25rem 0;
    }

    .p-submenu-icon {
      font-size: 0.875rem;
    }

    .p-menuitem {
      margin: 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #eff6ff;

    .p-menuitem-text,
    .p-menuitem-icon,
    .p-submenu-icon {
      color: #1d4ed8;
    }
  }

  .p-menuitem-badge {
    background: #1b234e;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;

    .p-menubar-button {
      display: flex;
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

      &:hover {
        color: #6c757d;
        background: #e9ecef;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
      }
    }

    .p-menubar-root-list {
      position: absolute;
      display: none;
      padding: 0.25rem 0;
      background: #ffffff;
      border: 0 none;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      width: 100%;

      .p-menu-separator {
        border-top: 1px solid #dee2e6;
        margin: 0.25rem 0;
      }

      .p-submenu-icon {
        font-size: 0.875rem;
      }

      > .p-menuitem {
        width: 100%;
        position: static;

        > .p-menuitem-link {
          padding: 0.75rem 1.25rem;
          color: #495057;
          border-radius: 0;
          transition: box-shadow 0.2s;
          user-select: none;

          .p-menuitem-text {
            color: #495057;
          }

          .p-menuitem-icon {
            color: #6c757d;
            margin-right: 0.5rem;
          }

          .p-submenu-icon {
            color: #6c757d;
          }

          &:not(.p-disabled):hover {
            background: #e9ecef;

            .p-menuitem-text {
              color: #495057;
            }

            .p-menuitem-icon,
            .p-submenu-icon {
              color: #6c757d;
            }
          }

          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem #bfdbfe;
          }

          > .p-submenu-icon {
            margin-left: auto;
            transition: transform 0.2s;
          }
        }

        &.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
          transform: rotate(-180deg);
        }
      }

      .p-submenu-list {
        width: 100%;
        position: static;
        box-shadow: none;
        border: 0 none;

        .p-submenu-icon {
          transition: transform 0.2s;
          transform: rotate(90deg);
        }

        .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
          transform: rotate(-90deg);
        }
      }

      .p-menuitem {
        width: 100%;
        position: static;
      }

      ul li {
        a {
          padding-left: 2.25rem;
        }

        ul li {
          a {
            padding-left: 3.75rem;
          }

          ul li {
            a {
              padding-left: 5.25rem;
            }

            ul li {
              a {
                padding-left: 6.75rem;
              }

              ul li a {
                padding-left: 8.25rem;
              }
            }
          }
        }
      }
    }

    &.p-menubar-mobile-active .p-menubar-root-list {
      display: flex;
      flex-direction: column;
      top: 100%;
      left: 0;
      z-index: 1;
    }
  }
}

.p-panelmenu {
  .p-panelmenu-header {
    > a {
      padding: 1.25rem;
      border: 1px solid #dee2e6;
      color: #6c757d;
      background: #f8f9fa;
      font-weight: 700;
      border-radius: 6px;
      transition: box-shadow 0.2s;

      .p-panelmenu-icon,
      .p-menuitem-icon {
        margin-right: 0.5rem;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
      }
    }

    &:not(.p-highlight):not(.p-disabled) > a:hover {
      background: #e9ecef;
      border-color: #dee2e6;
      color: #343a40;
    }

    &.p-highlight {
      margin-bottom: 0;

      > a {
        background: #f8f9fa;
        border-color: #dee2e6;
        color: #343a40;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(.p-disabled) > a:hover {
        border-color: #dee2e6;
        background: #e9ecef;
        color: #343a40;
      }
    }
  }

  .p-panelmenu-content {
    padding: 0.25rem 0;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    margin-bottom: 4px;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    .p-menuitem .p-menuitem-link {
      padding: 0.75rem 1.25rem;
      color: #495057;
      border-radius: 0;
      transition: box-shadow 0.2s;
      user-select: none;

      .p-menuitem-text {
        color: #495057;
      }

      .p-menuitem-icon {
        color: #6c757d;
        margin-right: 0.5rem;
      }

      .p-submenu-icon {
        color: #6c757d;
      }

      &:not(.p-disabled):hover {
        background: #e9ecef;

        .p-menuitem-text {
          color: #495057;
        }

        .p-menuitem-icon,
        .p-submenu-icon {
          color: #6c757d;
        }
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem #bfdbfe;
      }

      .p-panelmenu-icon {
        margin-right: 0.5rem;
      }
    }

    .p-submenu-list:not(.p-panelmenu-root-submenu) {
      padding: 0 0 0 1rem;
    }
  }

  .p-panelmenu-panel {
    margin-bottom: 4px;
  }

  .p-menuitem-badge {
    background: #1b234e;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.p-slidemenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  width: 12.5rem;

  .p-menuitem-link {
    padding: 0.75rem 1.25rem;
    color: #495057;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;

    .p-menuitem-text {
      color: #495057;
    }

    .p-menuitem-icon {
      color: #6c757d;
      margin-right: 0.5rem;
    }

    .p-submenu-icon {
      color: #6c757d;
    }

    &:not(.p-disabled):hover {
      background: #e9ecef;

      .p-menuitem-text {
        color: #495057;
      }

      .p-menuitem-icon,
      .p-submenu-icon {
        color: #6c757d;
      }
    }

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem #bfdbfe;
    }
  }

  &.p-slidemenu-overlay {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  .p-slidemenu-list {
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
    background: #eff6ff;

    .p-slidemenu-text,
    .p-slidemenu-icon {
      color: #1d4ed8;
    }
  }

  .p-slidemenu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }

  .p-slidemenu-icon {
    font-size: 0.875rem;
  }

  .p-slidemenu-backward {
    padding: 0.75rem 1.25rem;
    color: #495057;
  }

  .p-menuitem-badge {
    background: #1b234e;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.p-steps .p-steps-item {
  .p-menuitem-link {
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 6px;
    background: #ffffff;

    .p-steps-number {
      color: #495057;
      border: 1px solid #e9ecef;
      background: #ffffff;
      min-width: 2rem;
      height: 2rem;
      line-height: 2rem;
      font-size: 1.143rem;
      z-index: 1;
      border-radius: 50%;
    }

    .p-steps-title {
      margin-top: 0.5rem;
      color: #6c757d;
    }

    &:not(.p-disabled):focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
  }

  &.p-highlight {
    .p-steps-number {
      background: #eff6ff;
      color: #1d4ed8;
    }

    .p-steps-title {
      font-weight: 700;
      color: #495057;
    }
  }

  &:before {
    content: ' ';
    border-top: 1px solid #dee2e6;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem;
  }
}

.p-tabmenu {
  .p-tabmenu-nav {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-width: 0 0 2px 0;

    .p-tabmenuitem {
      margin-right: 0;

      .p-menuitem-link {
        border: solid #dee2e6;
        border-width: 0 0 2px 0;
        border-color: transparent transparent #dee2e6 transparent;
        background: #ffffff;
        color: #6c757d;
        padding: 1.25rem;
        font-weight: 700;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        transition: box-shadow 0.2s;
        margin: 0 0 -2px 0;

        .p-menuitem-icon {
          margin-right: 0.5rem;
        }

        &:not(.p-disabled):focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.2rem #bfdbfe;
        }
      }

      &:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
        background: #ffffff;
        border-color: #adb5bd;
        color: #6c757d;
      }

      &.p-highlight .p-menuitem-link {
        background: #ffffff;
        border-color: #1b234e;
        color: #1b234e;
      }
    }
  }

  .p-tabmenu-left-icon {
    margin-right: 0.5rem;
  }

  .p-tabmenu-right-icon {
    margin-left: 0.5rem;
  }

  .p-tabmenu-nav-btn.p-link {
    background: #ffffff;
    color: #1b234e;
    width: 3rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem #bfdbfe;
    }
  }
}

.p-tieredmenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  width: 12.5rem;

  .p-menuitem-link {
    padding: 0.75rem 1.25rem;
    color: #495057;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;

    .p-menuitem-text {
      color: #495057;
    }

    .p-menuitem-icon {
      color: #6c757d;
      margin-right: 0.5rem;
    }

    .p-submenu-icon {
      color: #6c757d;
    }

    &:not(.p-disabled):hover {
      background: #e9ecef;

      .p-menuitem-text {
        color: #495057;
      }

      .p-menuitem-icon,
      .p-submenu-icon {
        color: #6c757d;
      }
    }

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem #bfdbfe;
    }
  }

  &.p-tieredmenu-overlay {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  .p-submenu-list {
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  .p-menuitem {
    margin: 0;

    &:last-child {
      margin: 0;
    }

    &.p-menuitem-active > .p-menuitem-link {
      background: #eff6ff;

      .p-menuitem-text,
      .p-menuitem-icon,
      .p-submenu-icon {
        color: #1d4ed8;
      }
    }
  }

  .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }

  .p-submenu-icon {
    font-size: 0.875rem;
  }

  .p-menuitem-badge {
    background: #1b234e;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.p-inline-message {
  padding: 0.75rem 0.75rem;
  margin: 0;
  border-radius: 6px;

  &.p-inline-message-info {
    background: #e9e9ff;
    border: solid #696cff;
    border-width: 0px;
    color: #696cff;

    .p-inline-message-icon {
      color: #696cff;
    }
  }

  &.p-inline-message-success {
    background: #e4f8f0;
    border: solid #1ea97c;
    border-width: 0px;
    color: #1ea97c;

    .p-inline-message-icon {
      color: #1ea97c;
    }
  }

  &.p-inline-message-warn {
    background: #fff2e2;
    border: solid #cc8925;
    border-width: 0px;
    color: #cc8925;

    .p-inline-message-icon {
      color: #cc8925;
    }
  }

  &.p-inline-message-error {
    background: #ffe7e6;
    border: solid #ff5757;
    border-width: 0px;
    color: #ff5757;

    .p-inline-message-icon {
      color: #ff5757;
    }
  }

  .p-inline-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
  }

  .p-inline-message-text {
    font-size: 1rem;
  }

  &.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0;
  }
}

.p-message {
  margin: 1rem 0;
  border-radius: 6px;

  .p-message-wrapper {
    padding: 0.75rem 1rem;
  }

  .p-message-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
  }

  &.p-message-info {
    background: #e9e9ff;
    border: solid #696cff;
    border-width: 0 0 0 6px;
    color: #696cff;

    .p-message-icon,
    .p-message-close {
      color: #696cff;
    }
  }

  &.p-message-success {
    background: #e4f8f0;
    border: solid #1ea97c;
    border-width: 0 0 0 6px;
    color: #1ea97c;

    .p-message-icon,
    .p-message-close {
      color: #1ea97c;
    }
  }

  &.p-message-warn {
    background: #fff2e2;
    border: solid #cc8925;
    border-width: 0 0 0 6px;
    color: #cc8925;

    .p-message-icon,
    .p-message-close {
      color: #cc8925;
    }
  }

  &.p-message-error {
    background: #ffe7e6;
    border: solid #ff5757;
    border-width: 0 0 0 6px;
    color: #ff5757;

    .p-message-icon,
    .p-message-close {
      color: #ff5757;
    }
  }

  .p-message-text {
    font-size: 1rem;
    font-weight: 400;
  }

  .p-message-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .p-message-summary {
    font-weight: 700;
  }

  .p-message-detail {
    margin-left: 0.5rem;
  }
}

.p-toast {
  opacity: 0.9;

  .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .p-toast-message-content {
      padding: 1rem;
      border-width: 0 0 0 6px;

      .p-toast-message-text {
        margin: 0 0 0 1rem;
      }

      .p-toast-message-icon {
        font-size: 2rem;
      }

      .p-toast-summary {
        font-weight: 700;
      }

      .p-toast-detail {
        margin: 0.5rem 0 0 0;
      }
    }

    .p-toast-icon-close {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: transparent;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #bfdbfe;
      }
    }

    &.p-toast-message-info {
      background: #e9e9ff;
      border: solid #696cff;
      border-width: 0 0 0 6px;
      color: #696cff;

      .p-toast-message-icon,
      .p-toast-icon-close {
        color: #696cff;
      }
    }

    &.p-toast-message-success {
      background: #e4f8f0;
      border: solid #1ea97c;
      border-width: 0 0 0 6px;
      color: #1ea97c;

      .p-toast-message-icon,
      .p-toast-icon-close {
        color: #1ea97c;
      }
    }

    &.p-toast-message-warn {
      background: #fff2e2;
      border: solid #cc8925;
      border-width: 0 0 0 6px;
      color: #cc8925;

      .p-toast-message-icon,
      .p-toast-icon-close {
        color: #cc8925;
      }
    }

    &.p-toast-message-error {
      background: #ffe7e6;
      border: solid #ff5757;
      border-width: 0 0 0 6px;
      color: #ff5757;

      .p-toast-message-icon,
      .p-toast-icon-close {
        color: #ff5757;
      }
    }
  }
}

.p-galleria {
  .p-galleria-close {
    margin: 0.5rem;
    background: transparent;
    color: #f8f9fa;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;

    .p-galleria-close-icon {
      font-size: 2rem;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      color: #f8f9fa;
    }
  }

  .p-galleria-item-nav {
    background: transparent;
    color: #f8f9fa;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
    margin: 0 0.5rem;

    .p-galleria-item-prev-icon,
    .p-galleria-item-next-icon {
      font-size: 2rem;
    }

    &:not(.p-disabled):hover {
      background: rgba(255, 255, 255, 0.1);
      color: #f8f9fa;
    }
  }

  .p-galleria-caption {
    background: rgba(0, 0, 0, 0.5);
    color: #f8f9fa;
    padding: 1rem;
  }

  .p-galleria-indicators {
    padding: 1rem;

    .p-galleria-indicator {
      button {
        background-color: #ced4da;
        width: 1rem;
        height: 1rem;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 50%;

        &:hover {
          background: #adb5bd;
        }
      }

      &.p-highlight button {
        background: #eff6ff;
        color: #1d4ed8;
      }
    }
  }

  &.p-galleria-indicators-bottom .p-galleria-indicator,
  &.p-galleria-indicators-top .p-galleria-indicator {
    margin-right: 0.5rem;
  }

  &.p-galleria-indicators-left .p-galleria-indicator,
  &.p-galleria-indicators-right .p-galleria-indicator {
    margin-bottom: 0.5rem;
  }

  &.p-galleria-indicator-onitem .p-galleria-indicators {
    background: rgba(0, 0, 0, 0.5);

    .p-galleria-indicator {
      button {
        background: rgba(255, 255, 255, 0.4);

        &:hover {
          background: rgba(255, 255, 255, 0.6);
        }
      }

      &.p-highlight button {
        background: #eff6ff;
        color: #1d4ed8;
      }
    }
  }

  .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, 0.9);
    padding: 1rem 0.25rem;

    .p-galleria-thumbnail-prev,
    .p-galleria-thumbnail-next {
      margin: 0.5rem;
      background-color: transparent;
      color: #f8f9fa;
      width: 2rem;
      height: 2rem;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      border-radius: 50%;
    }

    .p-galleria-thumbnail-prev:hover,
    .p-galleria-thumbnail-next:hover {
      background: rgba(255, 255, 255, 0.1);
      color: #f8f9fa;
    }

    .p-galleria-thumbnail-item-content:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
  }
}

.p-galleria-mask,
.p-image-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-preview-indicator {
  background-color: transparent;
  color: #f8f9fa;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-image-preview-container:hover > .p-image-preview-indicator {
  background-color: rgba(0, 0, 0, 0.5);
}

.p-image-toolbar {
  padding: 1rem;
}

.p-image-action.p-link {
  color: #f8f9fa;
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: #f8f9fa;
    background-color: rgba(255, 255, 255, 0.1);
  }

  i {
    font-size: 1.5rem;
  }
}

.p-avatar {
  background-color: #dee2e6;
  border-radius: 6px;

  &.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;

    .p-avatar-icon {
      font-size: 1.5rem;
    }
  }

  &.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;

    .p-avatar-icon {
      font-size: 2rem;
    }
  }
}

.p-avatar-group .p-avatar {
  border: 2px solid #ffffff;
}

.p-badge {
  background: #1b234e;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;

  &.p-badge-secondary {
    background-color: #64748b;
    color: #ffffff;
  }

  &.p-badge-success {
    background-color: #22c55e;
    color: #ffffff;
  }

  &.p-badge-info {
    background-color: #1b234e;
    color: #ffffff;
  }

  &.p-badge-warning {
    background-color: #f59e0b;
    color: #ffffff;
  }

  &.p-badge-danger {
    background-color: #ef4444;
    color: #ffffff;
  }

  &.p-badge-lg {
    font-size: 1.125rem;
    min-width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
  }

  &.p-badge-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem;
  }
}

.p-chip {
  background-color: #dee2e6;
  color: #495057;
  border-radius: 16px;
  padding: 0 0.75rem;

  .p-chip-text {
    line-height: 1.5;
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }

  .p-chip-icon {
    margin-right: 0.5rem;
  }

  .pi-chip-remove-icon {
    margin-left: 0.5rem;
  }

  img {
    width: 2.25rem;
    height: 2.25rem;
    margin-left: -0.75rem;
    margin-right: 0.5rem;
  }

  .pi-chip-remove-icon {
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
  }
}

.p-inplace .p-inplace-display {
  padding: 0.75rem 0.75rem;
  border-radius: 6px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

  &:not(.p-disabled):hover {
    background: #e9ecef;
    color: #495057;
  }

  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfdbfe;
  }
}

.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #dee2e6;
  border-radius: 6px;

  .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #1b234e;
  }

  .p-progressbar-label {
    color: #ffffff;
    line-height: 1.5rem;
  }
}

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

  &.p-link {
    background: rgba(0, 0, 0, 0.7);

    &:hover {
      background: rgba(0, 0, 0, 0.8);
    }
  }

  .p-scrolltop-icon {
    font-size: 1.5rem;
    color: #f8f9fa;
  }
}

.p-skeleton {
  background-color: #dee2e6;
  border-radius: 6px;

  &:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
  }
}

.p-tag {
  background: #1b234e;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 6px;

  &.p-tag-success {
    background-color: #22c55e;
    color: #ffffff;
  }

  &.p-tag-info {
    background-color: #1b234e;
    color: #ffffff;
  }

  &.p-tag-warning {
    background-color: #f59e0b;
    color: #ffffff;
  }

  &.p-tag-danger {
    background-color: #ef4444;
    color: #ffffff;
  }

  .p-tag-icon {
    margin-right: 0.25rem;
    font-size: 0.75rem;
  }
}

.p-terminal {
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  padding: 1.25rem;

  .p-terminal-input {
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }
}

.p-button-label {
  font-weight: 500;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-tabview .p-tabview-nav {
  li .p-tabview-nav-link {
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }

  .p-tabview-ink-bar {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #1b234e;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
  }
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button,
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background-color: #1b234e;
}

.p-button {
  padding: 0 56px;
  font-size: 0.9rem;

  &:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #9dc1fb, 0 1px 2px 0 black;
  }

  &.p-button-secondary:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #b0b9c6, 0 1px 2px 0 black;
  }

  &.p-button-success:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #88eaac, 0 1px 2px 0 black;
  }

  &.p-button-info:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #9dc1fb, 0 1px 2px 0 black;
  }

  &.p-button-warning:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #facf85, 0 1px 2px 0 black;
  }

  &.p-button-help:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #d4aafb, 0 1px 2px 0 black;
  }

  &.p-button-danger:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #f7a2a2, 0 1px 2px 0 black;
  }
}

.p-datatable .p-datatable-tbody > tr {
  &.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 #1b234e;
  }

  &.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 #1b234e;
  }
}
