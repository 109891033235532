@import 'theme/theme.scss';
@import 'theme/overrides.scss';
@import 'base/reset.scss';
@import 'modal';
@import 'utility-classes';
@import 'typography';
@import 'variables';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';

html {
  width: 100% !important;
}

html body {
  width: 100% !important;
}

.mouse-pointer {
  cursor: pointer;
}

.text-blue-light {
  color: #94a3b8;
}

.text-gray {
  color: #b7b7b7;
}

.text-black {
  color: #0f172a;
}

.bg-blue {
  background-color: #1b234e;
}

.products-title {
  font-weight: 700;
  color: #0f172a;
}

.box-gray {
  background: #b7b7b7;
}

.box-red {
  background: #eb4940;
}

.box-yellow {
  background: #f4cb56;
}

.box-green {
  background: #75b77c;
}

.text-red {
  color: #eb4940;
}

.text-yellow {
  color: #f4cb56;
}

.text-green {
  color: #75b77c;
}

.text-status {
  font-weight: 700;
  font-size: 1rem;
}

.box-gray-transparent {
  background: #e9e9e9;
}

.box-red-transparent {
  background: #ffb9b5;
}

.box-yellow-transparent {
  background: #ffeebc;
}

.box-green-transparent {
  background: #c3eac7;
}

.text-sb {
  font-weight: 600;
}

a {
  text-decoration: none;
}

.surface-overlay {
  &:disabled {
    background-color: #f8fafc !important;
  }
}

.grayButton {
  border-color: #94a3b8 !important;
  color: #94a3b8 !important;
}

.confirmation-dialog.p-dialog {
  .p-dialog-content {
    padding: 3em !important;
    text-align: center;
  }

  .p-dialog-footer {
    display: flex;
    justify-content: space-between;
  }
}

input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
  border: 1px solid red !important;
}

.circle-box {
  width: 24px;
  height: 24px;
  font-size: 12px;
}

.border-light-blue {
  border-color: #94a3b8;
}

.ql-size-huge {
  font-size: 3rem;
}

.ql-size-large {
  font-size: 1.5rem;
}

.ql-size-small {
  font-size: 0.75rem;
}

.little-circle-box {
  width: 10px;
  height: 10px;
}

.h-2-5rem {
  height: 2.5rem;
}

.w-2-5rem {
  width: 2.5rem;
}

.h-2-7rem {
  height: 2.7rem;
}

.w-2-7rem {
  width: 2.7rem;
}

.little-circle-box {
  width: 10px;
  height: 10px;
}

.scrollbar::-webkit-scrollbar {
  width: 10px !important; /* width of the entire scrollbar */
}

.scrollbar::-webkit-scrollbar-track {
  background: var(--surface-overlay) !important; /* color of the tracking area */
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--primary-color) !important; /* color of the scroll thumb */
  border-radius: 20px !important; /* roundness of the scroll thumb */
  border: 2px solid var(--surface-overlay);
}

.break-word {
  word-break: break-word !important;
}

.bg-light-blue {
  background: #f1f7ff;
}

.max-h-38rem {
  max-height: 38rem;
}

.translate-x--30 {
  transform: translateX(-30%);
}

.p-dialog-content {
  @extend .scrollbar;
}

.trash {
  cursor: pointer;
  margin-bottom: -0.4rem;

  &:hover {
    filter: brightness(0) saturate(100%) invert(29%) sepia(99%) saturate(7304%) hue-rotate(357deg) brightness(102%)
      contrast(116%);
  }
}

.quill-comment {
  ul {
    margin-left: 1rem;
  }

  @for $i from 1 through 7 {
    .ql-indent-#{$i} {
      margin-left: #{$i}rem;
    }
  }
}

.search-allprojects::placeholder,
.search-allprojects *::placeholder {
  font-size: medium;
  color: #94a3b8;
  font-weight: 400;
}
