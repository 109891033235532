.pd-btn-big {
  padding: 0.6rem 5rem;
}

.pd-btn-small {
  padding: 0.6rem 2.5rem;
}

.bg-grey {
  background-color: #94a3b8;
}

.bg-blue {
  background-color: #1c234e;
}

.bg-succes {
  background-color: #34d399;
}

.bg-alert {
  background-color: #ef4967;
}

.hide {
  visibility: hidden;
}

.h-small {
  height: 2.5rem !important;
}

.w-small {
  width: 2.5rem !important;
}

.h-small-pixels {
  height: 55px;
}

.clearfix {
  overflow: auto;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

.color-primary-blue {
  color: #1b234e;
}

.margin-top-big {
  margin-top: 7rem;
}
