.p-sidebar-left {
  height: 100vh !important;
}

.p-sidebar {
  position: relative !important;
  background-color: #f1f7ff;
  margin-left: 0;
  height: 100%;
}

.p-sidebar .p-sidebar-content {
  padding: 0;
}

.p-sidebar-content {
  position: relative;
  overflow-y: hidden !important;
}

.p-sidebar .p-sidebar-header {
  padding: 0;
}

.p-button:focus {
  box-shadow: 0;
}

.p-avatar {
  background-color: #1b234e;
  color: #fff;
  border-radius: 6px;

  &.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;

    .p-avatar-icon {
      font-size: 1.5rem;
    }
  }

  &.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;

    .p-avatar-icon {
      font-size: 2rem;
    }
  }
}

.p-inputtext:enabled:focus {
  box-shadow: none;
}

.p-button.p-button-icon-only {
  width: 70px;
}

.p-inputtext {
  border: 1px solid var(--surface-border);
}

.p-button {
  height: 44px;
  width: 16rem;
}

.p-button-outlined {
  width: 2.8rem !important;
}

.p-paginator {
  margin-top: 1rem;
}

.p-badge {
  height: 16px !important;
  min-width: 0px !important;
  width: 16px !important;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 1rem !important;
  font-weight: normal !important;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0 !important;
}
