.form-container {
  background-color: white;
  height: 95%;
  width: 100%;
  border-radius: 0.4rem;

  .form-body {
    .text-area {
      resize: none;
    }

    .rectangles {
      display: flex;

      .rec-1 {
        margin-right: 0.2rem;
        height: 0.3rem;
        display: block;
        margin-top: 0.1rem;
        margin-bottom: 0.8rem;
        border-radius: 1rem;
      }

      .rec-2 {
        height: 0.3rem;
        display: block;
        margin-top: 0.1rem;
        margin-bottom: 0.8rem;
        border-radius: 1rem;
      }
    }
  }

  .form-footer {
    position: relative;

    .form-button {
      position: absolute;
      top: 50%;
      right: 0%;

      &-secondary {
        transform: translateX(1.4rem);
        position: absolute;
        top: 50%;
        right: 0%;
      }
      // p{
      // 	transform: translateX(1.5rem);
      // }
    }
  }
}

input.invalid {
  border: 1px solid red !important;
}

textarea.invalid {
  border: 1px solid red !important;
}

.invalid-feedback {
  color: red;
}
